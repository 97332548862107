<template>
  <div class="pacvue-shareOfVoiceGuide-wrap">
    <div class="pacvue-shareOfVoiceGuide-header">
      <img style="background: #e3e3e3; width: 100%; height: 135px" src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/executive-guide.png" />
    </div>
    <div class="pacvue-shareOfVoiceGuide-body p-5 pb-0">
      <div class="shareOfVoiceGuide-body-title flex items-center gap-2">
        {{ tipTitle }}
      </div>
      <div class="shareOfVoiceGuide-body-inc">
        {{ tipContent }}
      </div>
      <div v-if="guideDom" class="shareOfVoiceGuide-body-inc absolute bottom-[18px]">1/2</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
const tipTitle = $t(`Navigation Update`)
const tipContent = $t(`Competitive Renamed to Share of Voice`)
const guideDom = ref(null)
onMounted(() => {
  guideDom.value = document.querySelector("#pacvue-siderMenu_platformIntelligence")
})
</script>
<style lang="scss">
.pacvue-shareOfVoiceGuide-wrap {
  width: 500px;
  border-radius: 6px;
  overflow: hidden;
}
.shareOfVoiceGuide-body-title {
  color: #45464f;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.shareOfVoiceGuide-body-inc {
  color: #66666c;
  font-size: 12px;
  line-height: 18px;
}
.pacvue-shareOfVoiceGuideContent-wrapper {
  .driver-popover-footer {
    margin-bottom: 12px;
  }
  .driver-popover-tip {
    top: -9px !important;
    left: 66px;
  }
}
#driver-highlighted-element-stage {
  &.border-radius-12 {
    border-radius: 12px;
  }
}
</style>
