import { defineStore } from "pinia"
// utils
import { initwebSocket } from "@pacvue/frame/websocket"
// service
import { getUserInfo, getUserPreferences } from "../api/middle"

const state = () => {
  return {
    user: null,
    userSettings: null,
    userGuidanceInfo: null,
    sharePage: true,
    hasUnreadMsg: false
  }
}

const actions = {
  logout() {
    if (this.user) {
      this.user.userName = null
    }
  },
  userAction({ to } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let userInfo = JSON.parse(sessionStorage.getItem("useInfo") || "{}")
        if (!userInfo.userName || to.query.noCache) {
          userInfo = await getUserInfo()
          sessionStorage.setItem("Pacvue_token", localStorage.getItem("Pacvue_token"))
        }
        sessionStorage.setItem("useInfo", JSON.stringify(userInfo))
        localStorage.setItem("uid", userInfo?.userId) // 暂时保留
        localStorage.setItem("urole", userInfo.level) // 暂时保留 (有中台在用)
        if (window.spClient) {
          localStorage.setItem(`${userInfo.userId}_spClient`, window.spClient)
        }
        this.SET_USER(userInfo)
        window.socketErrCount = 0
        initwebSocket()
        resolve(userInfo)
      } catch (error) {
        console.log(error)
        reject()
      }
    })
  },
  async userSetting() {
    let setting = {}
    if (sessionStorage.getItem("setting")) {
      setting = JSON.parse(sessionStorage.getItem("setting"))
    } else {
      setting = await getUserPreferences(this.user.userId)
    }
    this.SET_USERSETTINGS(setting)
    window.HomePageId = this.userSettings?.homepageMenuId ?? -1
  },
  setUserGuidanceInfo(guidanceInfo) {
    this.userGuidanceInfo = guidanceInfo
  },
  SET_USER(user) {
    if (this.userSetting && this.userSetting.defaultCurrency) {
      user.defaultCurrency = this.userSetting.defaultCurrency
    }
    user.spClient = localStorage.getItem(`${user.userId}_spClient`) || ""
    this.user = user
  },
  SET_USERSETTINGS(setting) {
    this.userSettings = setting
    sessionStorage.setItem("setting", JSON.stringify(setting))
    window.perSetting = setting
    if (this.user && setting.defaultCurrency) {
      this.user.defaultCurrency = setting.defaultCurrency
    }
  },
  setHasUnreadMsg(state) {
    this.hasUnreadMsg = state
  }
}

const getters = {
  isDemo(state) {
    return [432, 3438].includes(state.user?.clientId) || state.user?.userName?.toLowerCase() === "Demo@pacvue.com".toLowerCase() || state.user?.userName?.toLowerCase() === "Pacvue Demo".toLowerCase()
  },
  defaultCountryCode(state) {
    return state.userSettings?.defaultCurrency || "US"
  },
  locale(state) {
    const userSettings = state.userSettings
    if (userSettings) {
      return userSettings.language || "EN"
    }
    if (window.location.href.includes(".cn")) {
      return "ZH"
    }
    return "EN"
  }
}

const useUserStore = defineStore("user", {
  state,
  getters,
  actions
})

export {
  state,
  getters,
  actions
}
export default useUserStore
