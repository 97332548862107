import request from "@pacvue/frame/request"

const { VITE_APP_USERURL, VITE_APP_MENU_API, VITE_APP_ADMIN_MIDDLE } = import.meta.env

const getUserInfo = () => {
  return request({
    url: `${VITE_APP_USERURL}User/userinfo`,
    method: "get"
  })
}

const getUserMenus = (params, productLine) => {
  return request({
    url: `${VITE_APP_MENU_API}user/menus`,
    method: "get",
    params,
    headers: { productline: productLine }
  })
}

const getUserPreferences = (userId, { productLine } = {}) => {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/getUserPreferences${userId ? "/" + userId : ""}`,
    method: "GET",
    headers: { productline: productLine }
  })
}

export { getUserInfo, getUserMenus, getUserPreferences }
