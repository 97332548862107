// Guide Manager 类的
import { PacvueGuide } from "@pacvue/element-plus"
class GuideManager {
  constructor() {
    this.guides = [] // 引导列表
    this.currentIndex = -1 // 当前引导的索引
    this.isPlaying = false // 是否正在进行引导
    this.storageKey = "GUIDE_SHOWN_RECORDS" // 存储
    this.onFinishCallback = null // 添加回调函数属性
  }

  // 获取展示记录
  getShownRecords(key) {
    try {
      let records = localStorage.getItem(key)
      return records === "true" || records === "1"
    } catch (e) {
      return false
    }
  }

  // 记录展示
  setShownRecord(key) {
    if (!key) return false
    localStorage.setItem(key, "1")
  }

  // 检查 guide 是否需要展示
  shouldShowGuide(guide) {
    // 检查是否已过期,当天不算过期
    if (guide.expireDate) {
      const today = new Date()
      const expireDate = new Date(guide.expireDate)
      // 重置时间为当天的0点0分0秒
      today.setHours(0, 0, 0, 0)
      expireDate.setHours(0, 0, 0, 0)
      if (today > expireDate) {
        return false
      }
    }
    const isShown = this.getShownRecords(guide.id)
    return !isShown
  }

  // 添加 guide
  addGuide(guide) {
    this.guides.push({
      id: guide.id || guide.key,
      weight: guide.weight,
      targetId: guide.targetId,
      content: guide.content,
      isAsync: guide.isAsync || false,
      condition: guide.condition,
      beforeStart: guide.beforeStart,
      expireDate: guide.expireDate,
      isLoaded: false
    })
    // 按权重排序
    this.guides.sort((a, b) => b.weight - a.weight)
  }

  // 开始展示 guides
  async start() {
    if (this.isPlaying) return
    this.isPlaying = true
    this.currentIndex = -1
    // 检查是否所有引导都已经展示过
    const allGuidesShown = this.guides.every((guide) => this.getShownRecords(guide.id))
    if (allGuidesShown) {
      this.isPlaying = false
      console.log("onLoad:引导结束")
      return
    }
    await this.showNext()
  }
  // 添加设置回调的方法
  onFinish(callback) {
    this.onFinishCallback = callback
  }
  // 展示下一个 guide
  async showNext() {
    if (!this.isPlaying) return
    this.currentIndex++
    if (this.currentIndex >= this.guides.length) {
      this.isPlaying = false
      // 引导结束事件
      console.log("Next:引导结束")
      if (this.onFinishCallback) {
        this.onFinishCallback()
      }
      return
    }

    const currentGuide = this.guides[this.currentIndex]

    // 检查是否需要展示
    if (!this.shouldShowGuide(currentGuide)) {
      return this.showNext()
    }

    // 处理异步 guide
    if (currentGuide.isAsync) {
      try {
        if (!currentGuide.isLoaded) {
          if (currentGuide.condition) {
            const shouldShow = await Promise.race([currentGuide.condition(), new Promise((_, reject) => setTimeout(() => reject("timeout"), 5000))])
            if (!shouldShow) {
              return this.showNext()
            }
          }
          currentGuide.isLoaded = true
        }
      } catch (error) {
        console.error(`Guide ${currentGuide.id} load failed:`, error)
        return this.showNext()
      }
    }

    // 展示当前 guide
    this.showGuide(currentGuide)
  }
  showGuide(guide) {
    if (!guide.id) {
      console.error(`Guide ${guide.id} 缺少 key`)
      return this.next()
    }
    // 检查目标 DOM 元素是否存在
    // const targetElement = document.getElementById(guide.targetId)
    // if (!guide.isAsync && !targetElement) {
    //   console.error(`Guide ${guide.id} 的目标元素 ${guide.targetId} 不存在，跳转下一步`)
    //   return this.next()
    // }
    // popver有300ms动画
    if (guide.beforeStart) {
      guide.beforeStart()
    }
    setTimeout(() => {
      let handler = PacvueGuide(guide.content)
      handler.start()
      this.setShownRecord(guide.id)
    }, 300)
  }

  next() {
    const popover = document.querySelector(".popover")
    if (popover) {
      popover.remove()
    }
    return this.showNext()
  }

  stop() {
    this.isPlaying = false
    this.currentIndex = -1
  }

  // 清除展示记录
  clearRecords(guideId) {
    if (guideId) {
      // 删除指定的 guide 记录
      localStorage.removeItem(guideId)
    } else {
      // 删除所有 guides 的记录
      this.guides.forEach((guide) => {
        localStorage.removeItem(guide.id)
      })
    }
  }
}

export { GuideManager }
