const productline = localStorage.getItem("productline")
const VITE_APP_NODE_ENV = import.meta.env.VITE_APP_NODE_ENV
const VITE_APP_APIENV = import.meta.env.VITE_APP_APIENV
const AMC_NODE_ENV = VITE_APP_NODE_ENV + "_" + VITE_APP_APIENV

let headers = {}
let VITE_APP_KEYWORDTAGURL = ""
let VITE_APP_AllAsinTags = ""
let VITE_APP_APIREPORT = ""
let VITE_APP_BASEURL = ""
let VITE_APP_USERURL = ""
let VITE_APP_META = ""
let VITE_APP_ADMIN_MIDDLE = ""
let VITE_APP_PERMSSION = ""
let VITE_APP_DOMAIN2 = ""
let VITE_APP_TAGURL = ""
let TAG_HEADER = ""
let VITE_APP_BASEURL_ACCOUNT = ""
let VITE_APP_META_FOR_SYNC = ""
let VITE_APP_COMMON = ""
let project_event_url = ""
let VITE_APP_DOWNLOAD = ""
switch (AMC_NODE_ENV) {
  case "development_us":
    VITE_APP_USERURL = "https://apiuser.pacvue.com/api/"
    VITE_APP_APIREPORT = "https://api-test.pacvue.com/amazon-main-api-hotfix/"
    VITE_APP_META = "https://api.pacvue.com/meta-api-{{platform}}/"
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.com/admin-middle-api-us/"
    VITE_APP_AllAsinTags = "https://api.pacvue.com/productcenterapi"
    VITE_APP_BASEURL = "https://api-test.pacvue.com/dspapi-dev/"
    VITE_APP_PERMSSION = "https://api-test.pacvue.com/admin-meta-api-test/"
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.com/meta-api-amazon"
    VITE_APP_TAGURL = "https://api-test.pacvue.com/tag-service-dev/"
    VITE_APP_BASEURL_ACCOUNT = 'https://api-test.pacvue.com/amazon-main-api-hotfix/api/'
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.com/meta-api-amazon/"
    VITE_APP_COMMON = "https://api-test.pacvue.com/growing-common-api-test/"
    project_event_url = "https://api-test.pacvue.com/event-dev-com/"
    VITE_APP_DOWNLOAD = "https://api-test.pacvue.com/downloadapitest/api/"
    VITE_APP_DOMAIN2 = "US"
    TAG_HEADER = "dspProduct"
    break
  case "development_cn":
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.cn/meta-api-amazon"
    VITE_APP_AllAsinTags = "https://api-test.pacvue.cn/productcenterapi"
    VITE_APP_APIREPORT = "https://api.pacvue.cn/pacvuemainapiv2/"
    VITE_APP_BASEURL = "https://api-test.pacvue.cn/dspapi-dev/"
    VITE_APP_USERURL = "https://apiuser.pacvue.cn/api/"
    VITE_APP_META = `https://api.pacvue.cn/meta-api-${productline}/`
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.cn/admin-middle-api-cn/"
    VITE_APP_PERMSSION = "https://api-test.pacvue.cn/admin-meta-api-test/"
    VITE_APP_TAGURL = "https://api.pacvue.cn/tag-service/"
    VITE_APP_BASEURL_ACCOUNT = 'https://api-test.pacvue.cn/amazon-main-api-test/api/'
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.cn/meta-api-amazon/"
    VITE_APP_COMMON = "https://api-test.pacvue.com/growing-common-api-test/"
    project_event_url = "https://api-test.pacvue.com/event-dev-com/"
    VITE_APP_DOWNLOAD = "https://api-test.pacvue.cn/downloadapitest/api/"
    VITE_APP_DOMAIN2 = "CN"
    TAG_HEADER = "dspDevelopmentCN"
    break
  case "test_us":
    VITE_APP_USERURL = "https://apiuser.pacvue.com/api/"
    VITE_APP_APIREPORT = "https://api-test.pacvue.com/amazon-main-api-hotfix/"
    VITE_APP_META = "https://api.pacvue.com/meta-api-{{platform}}/"
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.com/admin-middle-api-us/"
    VITE_APP_AllAsinTags = "https://api.pacvue.com/productcenterapi"
    VITE_APP_BASEURL = "https://api-test.pacvue.com/dspapi-dev/"
    VITE_APP_PERMSSION = "https://api-test.pacvue.com/admin-meta-api-test/"
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.com/meta-api-amazon"
    VITE_APP_TAGURL = "https://api-test.pacvue.com/tag-service-dev/"
    VITE_APP_BASEURL_ACCOUNT = 'https://api-test.pacvue.com/amazon-main-api-hotfix/api/'
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.com/meta-api-amazon/"
    VITE_APP_COMMON = "https://api-test.pacvue.com/growing-common-api-test/"
    project_event_url = "https://api-test.pacvue.com/event-dev-com/"
    VITE_APP_DOWNLOAD = "https://api-test.pacvue.com/downloadapitest/api/"
    VITE_APP_DOMAIN2 = "US"
    TAG_HEADER = "dspProduct"
    break
  case "test_cn":
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.cn/meta-api-amazon"
    VITE_APP_AllAsinTags = "https://api-test.pacvue.cn/productcenterapi"
    VITE_APP_APIREPORT = "https://api.pacvue.cn/pacvuemainapiv2/"
    VITE_APP_BASEURL = "https://api-test.pacvue.cn/dspapi-dev/"
    VITE_APP_USERURL = "https://apiuser.pacvue.cn/api/"
    VITE_APP_META = `https://api.pacvue.cn/meta-api-${productline}/`
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.cn/admin-middle-api-cn/"
    VITE_APP_PERMSSION = "https://api-test.pacvue.cn/admin-meta-api-test/"
    VITE_APP_TAGURL = "https://api.pacvue.cn/tag-service/"
    VITE_APP_BASEURL_ACCOUNT = 'https://api-test.pacvue.cn/amazon-main-api-test/api/'
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.cn/meta-api-amazon/"
    VITE_APP_COMMON = "https://api-test.pacvue.com/growing-common-api-test/"
    project_event_url = "https://api-test.pacvue.com/event-dev-com/"
    VITE_APP_DOWNLOAD = "https://api-test.pacvue.cn/downloadapitest/api/"
    VITE_APP_DOMAIN2 = "CN"
    TAG_HEADER = "dspDevelopmentCN"
    break
  case "production_cn":
    VITE_APP_USERURL = "https://apiuser.pacvue.cn/api/"
    VITE_APP_APIREPORT = "https://api.pacvue.cn/pacvuemainapiv2/"
    VITE_APP_META = "https://api.pacvue.cn/meta-api-{{platform}}/"
    VITE_APP_AllAsinTags = "https://api.pacvue.cn/productcenterapi"
    VITE_APP_BASEURL = "https://api.pacvue.cn/dspapi/"
    VITE_APP_PERMSSION = "https://api.pacvue.cn/admin-meta-api-cn/"
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.cn/meta-api-amazon"
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.cn/admin-middle-api-cn/"
    VITE_APP_TAGURL = "https://api.pacvue.cn/tag-service/"
    VITE_APP_BASEURL_ACCOUNT = "https://api.pacvue.cn/pacvuemainapiv2/api/"
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.cn/meta-api-amazon/"
    VITE_APP_COMMON = "https://api.pacvue.cn/growing-common-api/"
    project_event_url = "https://api.pacvue.cn/event/"
    VITE_APP_DOWNLOAD = "https://api.pacvue.cn/downloadapi/api/"
    VITE_APP_DOMAIN2 = "CN"
    TAG_HEADER = "dspDevelopmentCN"
    break
  case "production_eu":
    VITE_APP_USERURL = "https://apiuser-eu.pacvue.com/api/"
    VITE_APP_APIREPORT = "https://api-eu.pacvue.com/pacvuemainapiv2/"
    VITE_APP_META = "https://api-eu.pacvue.com/meta-api-{{platform}}/"
    VITE_APP_AllAsinTags = "https://api-eu.pacvue.com/productcenterapi"
    VITE_APP_BASEURL = "https://api-eu.pacvue.com/dspapi/"
    VITE_APP_PERMSSION = "https://api-eu.pacvue.com/admin-meta-api-eu/"
    VITE_APP_KEYWORDTAGURL = "https://api-eu.pacvue.com/meta-api-amazon"
    VITE_APP_ADMIN_MIDDLE = "https://api-eu.pacvue.com/admin-middle-api-eu/"
    VITE_APP_TAGURL = "https://api-eu.pacvue.com/tag-service/"
    VITE_APP_BASEURL_ACCOUNT = "https://api-eu.pacvue.com/pacvuemainapiv2/api/"
    VITE_APP_META_FOR_SYNC = "https://api-eu.pacvue.com/meta-api-amazon/"
    VITE_APP_COMMON = "https://api-eu.pacvue.com/growing-common-api/"
    project_event_url = "https://api-eu.pacvue.com/event/"
    VITE_APP_DOWNLOAD = "https://api-eu.pacvue.com/downloadapi/api/"
    VITE_APP_DOMAIN2 = "EU"
    TAG_HEADER = "dspProductEU"
    break
  case "production_us":
    VITE_APP_USERURL = "https://apiuser.pacvue.com/api/"
    VITE_APP_APIREPORT = "https://api.pacvue.com/pacvuemainapiv2/"
    VITE_APP_META = "https://api.pacvue.com/meta-api-{{platform}}/"
    VITE_APP_KEYWORDTAGURL = "https://api.pacvue.com/meta-api-amazon"
    VITE_APP_ADMIN_MIDDLE = "https://api.pacvue.com/admin-middle-api-us/"
    VITE_APP_AllAsinTags = "https://api.pacvue.com/productcenterapi"
    VITE_APP_BASEURL = "https://api.pacvue.com/dspapi/"
    VITE_APP_PERMSSION = "https://api.pacvue.com/admin-meta-api-us/"
    VITE_APP_TAGURL = "https://api.pacvue.com/tag-service/"
    VITE_APP_BASEURL_ACCOUNT = "https://api.pacvue.com/pacvuemainapiv2/api/"
    VITE_APP_META_FOR_SYNC = "https://api.pacvue.com/meta-api-amazon/"
    VITE_APP_COMMON = "https://api.pacvue.com/growing-common-api/"
    project_event_url = "https://api.pacvue.com/event/"
    VITE_APP_DOWNLOAD = "https://api-test.pacvue.com/downloadapitest/api/"
    VITE_APP_DOMAIN2 = "US"
    TAG_HEADER = "dspProduct"
    break
  // case "preview":
  //   project_rule_api = "https://api.pacvue.com/rule-api-grey"
  //   project_rule_meta_api = `https://api.pacvue.com/meta-api-${productline}-grey`
  //   project_rule_dsp_api = "https://api.pacvue.com/tag-service-pre/api"
  //   break
}

export {
  TAG_HEADER,
  productline,
  AMC_NODE_ENV,
  VITE_APP_KEYWORDTAGURL,
  VITE_APP_AllAsinTags,
  VITE_APP_APIREPORT,
  VITE_APP_BASEURL,
  VITE_APP_USERURL,
  VITE_APP_META,
  VITE_APP_ADMIN_MIDDLE,
  VITE_APP_PERMSSION,
  VITE_APP_DOMAIN2,
  VITE_APP_TAGURL,
  VITE_APP_BASEURL_ACCOUNT,
  VITE_APP_META_FOR_SYNC,
  VITE_APP_COMMON,
  project_event_url,
  headers
}
