<template>
  <div class="pacvue-recommendation-menuGuide-wrap">
    <div class="pacvue-recommendation-menuGuide-header">
      <img style="background: #e3e3e3; width: 100%; height: 135px" src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/executive-guide.png" />
    </div>
    <div class="pacvue-recommendation-menuGuide-body">
      <div v-for="item in descList" :key="item.title" :class="{ 'pt-3': true }">
        <div class="text-[var(--color-title--)] font-semibold flex items-center">
          <el-icon :size="24" style="fill: var(--root-menu-highlight)"><PacvueIconPacvueStarLine /></el-icon>
          <span class="ml-2">{{ item.title }}</span>
        </div>
        <div class="text-[var(--color-text--)]">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
let descList = [
  {
    title: $t("Feature Upgrade Announcement"),
    content: $t("We’re excited to announce that Paid Search Audit has been fully upgraded to Recommendation!")
  },
  {
    title: $t("Brand-New UI Experience"),
    hasStar: true,
    content: $t("A more intuitive interface that helps you easily understand various types of recommendations and optimize your ads more effectively.")
  },
  {
    title: $t("Amazon Recommendations Added"),
    hasStar: true,
    content: $t("amskey3345")
  }
]
</script>
<style scoped lang="scss">
.pacvue-recommendation-menuGuide-wrap {
  width: 497px;
  border-radius: 6px;
  overflow: hidden;
  .pacvue-recommendation-menuGuide-header {
    padding: 0px 20px;
    background: linear-gradient(to bottom, white 0%, #fffdfd 47%, #e3e3e3 100%);
    height: 135px;
    position: relative;
    .menuGuide-left {
      position: absolute;
      width: 214px;
      left: 0px;
    }
    .menuGuide-center {
      width: 300px;
      position: absolute;
      bottom: 0px;
      left: 134px;
    }
    .menuGuide-right {
      position: absolute;
      width: 150px;
      right: 0px;
    }
  }
  .pacvue-recommendation-menuGuide-body {
    padding: 12px;
    padding-bottom: 0px;
  }
}
</style>
<style lang="scss">
.pacvue-menu-guide-step {
  background: var(--pac-theme-color);
  width: 46px;
  border-radius: 12px;
  display: flex;
  padding-left: 0px !important;
  justify-content: center;
  margin: auto;
  padding-right: 0px !important;
  .el-icon {
    color: white !important;
  }
}
.pacvue-menuGuideContent-wrapper {
  .driver-popover-footer {
    margin-bottom: 12px;
  }
}
#driver-highlighted-element-stage {
  &.border-radius-12 {
    border-radius: 12px;
  }
}
</style>
