import { createPinia } from "pinia"
import { StorePersistPlugin } from "./store-persist-plugin"
//导出持久化前缀
export { PERSIST_KEY_PRE } from "@pacvue/store/utils/store-persist-plugin"
//导出store模块中的内容
export * from "./modules/index"

export default function createCustomPinia() {
  const pinia = createPinia()
  pinia.use(StorePersistPlugin)
  return pinia
}
