import { whiteLabelsConfig } from "../config/white-label"
import config from "@~/config.js"

const { VITE_APP_APIENV, VITE_APP_NODE_ENV, MODE } = import.meta.env

const isCN = VITE_APP_APIENV.toLowerCase().indexOf("cn") !== -1
const isEU = VITE_APP_APIENV.toLowerCase().indexOf("eu") !== -1
const isDev = MODE.toLowerCase().indexOf("dev") !== -1 || MODE.toLowerCase().indexOf("hotfix") !== -1 || MODE.toLowerCase().indexOf("test") !== -1
const isProd = VITE_APP_NODE_ENV.toLowerCase() === "production"
const isLocal = window.location.href.indexOf("localhost") !== -1
const hostnamePrefix = window.location.hostname.split(".").slice(0, -1).join(".")
const spWhiteLabelConfig = whiteLabelsConfig.find(([K]) => K.includes(hostnamePrefix))?.[1] ?? {}
const isSpWhiteLabel = Object.keys(spWhiteLabelConfig).length > 0
const isWhiteLabel = isSpWhiteLabel || (document.getElementById("fav-title")?.content?.lastIndexOf("Pacvue") === -1 && window.location.href.indexOf("localhost") === -1)
const isGroupM = window.location.hostname.includes("groupm")
let tabTitle
if (document.getElementById("fav-title")?.content?.lastIndexOf("Pacvue") != -1) {
  tabTitle = "Pacvue"
} else {
  tabTitle = document.getElementById("fav-title")?.content
}

if (isSpWhiteLabel) {
  // 修改 fav-title
  document.getElementById("fav-title").content = spWhiteLabelConfig.favTitle
  // 修改 fav-icon
  document.getElementById("fav-icon").setAttribute("href", spWhiteLabelConfig.favIcon)
}

const judgeShare = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.has("shareId") || searchParams.has("AMCT")
}

const judgeHqShow = () => {
  const productLine = window.productline || localStorage.getItem("productline")
  const { availablePlatforms } = JSON.parse(sessionStorage.getItem("useInfo") || "{}")
  return !judgeShare() && config.retailer && availablePlatforms.includes("retailer") && productLine !== "retailer" && (!isWhiteLabel || isGroupM)
}

export { isCN, isEU, isDev, isProd, isLocal, isWhiteLabel, isGroupM, spWhiteLabelConfig, isSpWhiteLabel, tabTitle, judgeShare, judgeHqShow }
