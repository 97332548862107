import { CoralogixRum } from "@coralogix/browser"
import config from "@~/config.js"
import { app } from "./frame"

const { coralogixConfig } = config

const { VITE_APP_NODE_ENV, VITE_APP_APIENV } = import.meta.env

const applicationInfo = {
  amazon: ["Pacvue-Amazon"],
  walmart: ["Pacvue-Walmart"],
  instacart: ["Pacvue-Instacart"],
  dsp: ["Pacvue-DSP"],
  criteo: ["Pacvue-Criteo"],
  citrus: ["Pacvue-Citrus"],
  kroger: ["Pacvue-Kroger"],
  samsclub: ["Pacvue-Samsclub"],
  commerce: ["Pacvue-Commerce"]
}
export const initCoralogix = (env) => {
  const productline = localStorage.getItem("productline")
  // 测试实例为空，则默认取用正式环境配置
  const configInfo = coralogixConfig.length === 0 ? applicationInfo[productline] : coralogixConfig
  if (configInfo?.length >= 0) {
    CoralogixRum.init({
      public_key: "cxtp_M8etMw1iJFfFUBxR8MvAqdksqDhoe6",
      application: configInfo[0],
      version: `${APP_MESSAGE?.version}-${env}` || "Undefined",
      coralogixDomain: "US2",
      labels: { env: `${VITE_APP_NODE_ENV}-${VITE_APP_APIENV}` },
      /* 其他可配置项 */
      sessionRecordingConfig: {
        enable: true,
        autoStartSessionRecording: true,
        sessionRecordingSampleRate: 100
        /* sampling: {
          // record all mouse movements
          // if set to false, no mouse movements will be recorded
          mousemove: true,

          // record all mouse interactions
          // if set to false, no mouse interactions will be recorded
          // mouseInteraction: true,
          mouseInteraction: {
            MouseUp: true,
            MouseDown: true,
            Click: true,
            ContextMenu: true,
            DblClick: true,
            Focus: true,
            Blur: true,
            TouchStart: true,
            TouchEnd: true
          },

          // set the interval of a scrolling event
          scroll: 150, // does not emit twice in 150ms
          //if not declared, all scrolling events will be emitted

          // set the interval of a media interaction event
          media: 800, //if not declared, all media events will be emitted

          // set the timing of record input
          input: "last"
          // When inputting multiple characters, only record the final input
          // Selecting 'all' instead of 'last' will record all typed inputs
        },
        blockClass: "rr-block", // Use a string or RegExp to redact all elements that contain this class, defaults to rr-block.
        ignoreClass: "rr-ignore", // Use a string or RegExp to Ignore all events that contain this class, defaults to rr-ignore.
        maskTextClass: "rr-mask", // Use a string or RegExp to mask all elements that contain this class, defaults to rr-mask.
        maskAllInputs: false, // Mask all input content as * (Default false), refer to Input types.
        maskInputOptions: { password: true } // Mask some kinds of input as *, By Default the SDK masking password inputs. */
      }
      /* ignoreUrls,
      ignoreErrors */
    })
    // 获取本次会话用户信息
    app.config.globalProperties.$eventHub.on("getUserInfo", (data) => {
      /*       console.log("new user info")
      // 事件总线里过滤掉黑名单用户
      if (configInfo[3]?.includes(data?.userId)) {
        datadogRum?.stopSessionReplayRecording()
        datadogRum?.clearUser()
        return
      } */
      CoralogixRum.setUserContext({
        user_id: `${data?.userId}`,
        user_name: data?.userName,
        user_email: data?.mail,
        user_metadata: {
          clientId: data?.clientId,
          byPacvuer: data?.byPacvuer || "Real User"
        }
      })
    })
  }
}
