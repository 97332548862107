<template>
  <el-scrollbar wrap-class="platform-switch-panel pt-2 max-h-[calc(100vh-72px)]">
    <template v-for="[groupName, groupItems] in Object.entries(platforms)" :key="groupName">
      <template v-if="groupItems.length > 0">
        <div class="group-title flex items-center h-7 bg-[var(--el-color-primary-light-9)]" :class="{ 'is-itsumo': groupName === spClient }">
          <i class="w-1 h-4 bg-[var(--pac-theme-color)]"></i>
          <span class="ml-3 leading-7 font-semibold text-[var(--pac-theme-color)]">{{ groupName }}</span>
        </div>
        <div class="group-list flex flex-wrap justify-between px-3 py-1" :class="{ 'is-itsumo': groupName === spClient }">
          <PacvueTooltip
            v-for="platform in groupItems"
            :key="platform.productLine"
            effect="dark"
            placement="top"
            :disabled="platform.available"
            :show-after="500"
            :content="$t('The platform is not activated, please contact us.')"
          >
            <div
              class="plt-wrapper flex flex-col mt-1 w-16 h-16 items-center py-2 rounded-md cursor-pointer transition-all duration-300"
              :class="[
                platform.available ? 'hover:bg-[var(--el-color-primary-light-9)]' : 'opacity-[40%] !cursor-not-allowed',
                { '!bg-[var(--el-color-primary-light-8)]': judgeIsCurrentPlatform(platform.productLine) }
              ]"
              @click="handlePlatformClick(platform)"
            >
              <el-icon :size="24"><component :is="platform.iconName" /></el-icon>
              <span
                class="mt-1 font-medium text-[#66666c] leading-5 text-[12px]"
                :class="[
                  { 'text-[var(--pac-theme-color)] font-semibold': judgeIsCurrentPlatform(platform.productLine) },
                  platform.group === 'MarketInsights' ? 'inline-block !leading-3 text-center break-keep' : 'max-w-[64px] one-line-wrap'
                ]"
              >
                {{ platform.name }}
              </span>
            </div>
          </PacvueTooltip>
          <template v-if="groupItems.length % 3 !== 0">
            <div v-for="placeholder in 3 - (groupItems.length % 3)" :key="placeholder" class="w-16 h-16"></div>
          </template>
        </div>
      </template>
    </template>
  </el-scrollbar>
</template>

<script setup>
import { ref, computed, reactive, watch } from "vue"
// store
import { useUserStore } from "@/store"
// utils
import { jumpToRetailDashboard } from "../../../utils/common"
import { recordNavStartTime } from "../../../utils/nav"
// constants
import { DOMAIN_MAPPER_TEST, DOMAIN_MAPPER_PROD } from "../../../constants/platform"
import { isWhiteLabel, isDev } from "../../../constants/env"
// config
import { whiteLabelsConfig } from "../../../config/white-label"
import { pltSwitchActiveOnlyClients } from "../../../config/layout"

const userStore = useUserStore()

const pseudoProductLine = window.pseudoProductLine
const productLine = pseudoProductLine || localStorage.getItem("productline")
const spClient = computed(() => userStore.user?.spClient)
const availablePlatforms = computed(() => userStore.user?.availablePlatforms ?? [])
const platforms = reactive({
  ADVERTISING: [
    { productLine: "amazon", name: "Amazon", iconName: "PacvueIconAmazonSmall", available: false, group: "Ads" },
    { productLine: "dsp", name: "ADSP", iconName: "PacvueIconAmazonSmall", available: false, group: "Ads" },
    { productLine: "walmart", name: "Walmart", iconName: "PacvueIconWalmartSmall", available: false, group: "Ads" },
    { productLine: "instacart", name: "Instacart", iconName: "PacvueIconInstacartSmall", available: false, group: "Ads" },
    { productLine: "criteo", name: "Criteo", iconName: "PacvueIconCriteoSmall", available: false, group: "Ads" },
    { productLine: "citrus", name: "Citrus", iconName: "PacvueIconCitrusSmall", available: false, group: "Ads" },
    { productLine: "ebay", name: "eBay", iconName: "PacvueIconEbaySmall", available: false, group: "Ads" },
    { productLine: "kroger", name: "Kroger", iconName: "PacvueIconKrogerSmall", available: false, group: "Ads" },
    { productLine: "bol", name: "Bol", iconName: "PacvueIconBolSmall", available: false, group: "Ads" },
    { productLine: "doordash", name: "Doordash", iconName: "PacvueIconDoordashSmall", available: false, group: "Ads" },
    { productLine: "samsclub", name: "Sam's club", iconName: "PacvueIconSamsclubSmall", available: false, group: "Ads" },
    { productLine: "mercado", name: "Mercado", iconName: "PacvueIconMercadoSmall", available: false, group: "Ads" },
    { productLine: "chewy", name: "Chewy", iconName: "PacvueIconChewySmall", available: false, group: "Ads" },
    { productLine: "target", name: "Target", iconName: "PacvueIconTargetSmall", available: false, group: "Ads" }
  ],
  COMMERCE: [
    { productLine: "commerce", name: "Amazon", iconName: "PacvueIconAmazonSmall", available: false, group: "Commerce" },
    { productLine: "commerce-walmart", name: "Walmart", iconName: "PacvueIconWalmartSmall", available: false, group: "Commerce" },
    { productLine: "commerce-target", name: "Target", iconName: "PacvueIconTargetSmall", available: false, group: "Commerce" }
  ],
  "Market Insights": ["amazon", "dsp", "commerce"].includes(productLine)
    ? [{ productLine: "mt360", name: "Market Insights", iconName: "PacvueIconMt360Small", available: false, group: "MarketInsights" }]
    : []
})

const pseudoProductLineMapper = {
  Itsumo: { name: "Itsumo DB", icon: "PacvueIconItsumoSmall" }
}

const findPlatformGroup = (plt) => {
  if (plt === "mt360") {
    return "Market Insights"
  } else if (plt.startsWith("commerce")) {
    return "COMMERCE"
  } else {
    return "ADVERTISING"
  }
}

const availabilitySetter = (plt) => {
  const groupName = findPlatformGroup(plt)
  const index = platforms[`${groupName}`].findIndex((item) => item.productLine === plt)
  index !== -1 && (platforms[`${groupName}`][index].available = true)
}

const judgeIsCurrentPlatform = (plt) => {
  if (plt === "commerce") {
    return ["commerce", "commerce-amazon"].includes(productLine)
  } else {
    return plt === productLine
  }
}

watch(
  availablePlatforms,
  (val) => {
    // 伪产品线特殊处理, 追加一组 group, 例如 Itsumo
    const spPlt = pseudoProductLine || spClient.value
    if (spPlt) {
      platforms[spPlt] = [{ productLine: spPlt, name: pseudoProductLineMapper[spPlt].name, iconName: pseudoProductLineMapper[spPlt].icon, available: true, group: spPlt }]
    }
    val.forEach((plt) => availabilitySetter(plt))
    if (isWhiteLabel) {
      if (val.includes("dsp") && ["amazon", "dsp"].includes(productLine)) {
        ;["amazon", "dsp"].forEach((plt) => availabilitySetter(plt))
      }
      if (window.location.href.includes("oceanwing.com.cn") && val.length > 0) {
        ;["amazon", "dsp"].forEach((plt) => availabilitySetter(plt))
      }
    }
    if (pltSwitchActiveOnlyClients.includes(userStore.user?.clientId)) {
      for (const [groupName, groupItems] of Object.entries(platforms)) {
        platforms[groupName] = groupItems.filter((item) => item.available)
      }
    }
    // Market Insights(H10) 必须有权限才显示
    platforms["Market Insights"] = platforms["Market Insights"].filter((item) => item.available)
  },
  { immediate: true }
)

const handlePlatformClick = (platform) => {
  if (!platform.available || judgeIsCurrentPlatform(platform.productLine)) return
  // bol & chewy 默认去新站点
  if (["bol", "chewy"].includes(platform.productLine)) {
    platform.productLine = `${platform.productLine}v2`
  }
  const DOMAIN_MAPPER = isDev ? DOMAIN_MAPPER_TEST : DOMAIN_MAPPER_PROD
  const subDomain = DOMAIN_MAPPER[platform.productLine]?.subDomain ?? platform.productLine
  const pathPrefix = DOMAIN_MAPPER[platform.productLine]?.pathPrefix ?? ""
  const isDomainUnified = DOMAIN_MAPPER[platform.productLine]?.isReleased ?? false
  recordNavStartTime()
  jumpToRetailDashboard(subDomain, { mode: "_self", isDomainUnified }, pathPrefix)
}
</script>

<style scoped lang="scss">
.one-line-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
// 在业务平台中, Itsumo Group 需要使用它定制的主题色
.group-title.is-itsumo {
  background-color: #fcf4f4;
  > i {
    background-color: #ca1a1d;
  }
  > span {
    color: #ca1a1d;
  }
}
.group-list.is-itsumo {
  .plt-wrapper:hover {
    background-color: #fcf4f4;
  }
}
:deep(.el-scrollbar__bar.is-vertical) {
  top: 8px;
  right: -2px;
  .el-scrollbar__thumb {
    width: 4px;
  }
}
</style>
