import * as storeModule from "../index"
import { getUseStoreKey, strCapitalize } from "@pacvue/store/utils/util"

export default {
  platformName(state) {
    var useStoreKey = getUseStoreKey(state.platform)
    // eslint-disable-next-line import/namespace
    if (storeModule[useStoreKey]) {
      // eslint-disable-next-line import/namespace
      var store = storeModule[useStoreKey]()
      return store.platformName
    }
    return state.platform
  },
  curStore(state) {
    var storeName = `use${strCapitalize(state.platform)}Store`
    // eslint-disable-next-line import/namespace
    var curStore = storeModule[storeName]()
    return curStore
  },
  appStore(state) {
    var storeName = `useAppStore`
    // eslint-disable-next-line import/namespace
    return storeModule[storeName]()
  },
  selectedProfiles(state) {
    const selectedProfiles = this.curStore.selectedProfiles || []
    // const pathName = this.appStore.activePath?.toLowerCase()
    // if (pathName != "/campaign/campaigntagging") {
    //   if (selectedProfiles.length == 1 && selectedProfiles[0] == -10) {
    //     selectedProfiles = []
    //   }
    // }
    return selectedProfiles || []
  },
  selectedCamTags(state) {
    return this.curStore.selectedCamTags || []
  },
  selectedPortfolios(state) {
    return this.curStore.selectedPortfolios || []
  },
  profileIds(state) {
    var selProfileIds = this.selectedProfiles || []
    if (!selProfileIds.length || selProfileIds.length == this.adProfileIds.length) {
      selProfileIds = this.adProfileIds
    }
    return selProfileIds
  },
  // campaignTags(state) {//home页面不需要同步
  //   return this.curStore.selectedCamTags || []
  // },
  // portfolio(state) {//home页面不需要同步
  //   return this.curStore.selectedPortfolios || []
  // },
  selectedCampaigns(state) {
    return this.curStore.selectedCampaigns || []
  },
  selectedAdgroups(state) {
    return this.curStore.selectedAdgroups || []
  },
  adProfileList() {
    return this.curStore.profileList || []
  },
  adProfileIds() {
    return this.adProfileList.map((item) => {
      return item.id
    })
  },
  adAllProfileList() {
    return this.curStore.allProfileList || []
  },
  adAllProfileIds() {
    return this.adAllProfileList.map((item) => {
      return item.id
    })
  },
  defaultCurrencyToMarket(state) {
    //如果home,advertising,ProductCneter
    var userStore = storeModule.useUserStore()
    var selectedProfiles = state.selectedProfiles //选中的profile
    var cacheData = state.adProfileList || [] //所有的profile
    var checkedPid
    if (selectedProfiles.length == 0) {
      checkedPid = cacheData
    } else {
      checkedPid = cacheData.filter((x) => {
        return selectedProfiles.indexOf(x.id) != -1
      })
    }
    var currency
    currency = checkedPid.map((r) => {
      return r.countryCode
    })
    if (Array.from(new Set(currency)).length > 1) {
      // 存在不同的市场 使用setting的currency
      return userStore.user.defaultCurrency
    } else {
      // 否则使用单个市场的currency
      return Array.from(new Set(currency))[0] || userStore?.user?.defaultCurrency
    }
  },
  advertisingExplorerShowMode() {
    return this.curStore.advertisingExplorerShowMode || "Drawer"
  }
}
