/* eslint-disable no-undef */
import { computed, defineComponent, onUnmounted, inject, h, unref, watchEffect, shallowRef, readonly, ref, watch } from "vue"
import { useUserStore, useCommonStore, useAppStore, PERSIST_KEY_PRE } from "@/store"
import { commonJS, pacvueToken } from "@pacvue/utils"
import common from "@pacvue/utils"
import { useRoute } from "vue-router"
import { PacvueMessageBox } from "@pacvue/element-plus"
import { dayjs } from "@pacvue/element-plus"
import { getCrossAuthCode } from "@/api/user"
import useAdvertisingStore from "@/views/Advertising/advertising-store"
import request from "@pacvue/frame/request"
import axios from "axios"
import { SearchProductAdForAsinSku } from "@/api/Advertising/Explorer"
import { escape } from "lodash-es"
import { localForageClear } from "@/utils/usePacvueLocalforage"
import { usePacvuePermission } from "@/utils/PacvuePermission/util"
// import advancedFormat from "dayjs/plugin/advancedFormat"

// dayjs.extend(advancedFormat)
// import router from '@/router'

export function usePermission() {
  const userStore = useUserStore()
  const userRole = computed(() => userStore.user?.userRole === "User")
  const adminRole = computed(() => userStore.user?.userRole === "Admin")
  const readRole = computed(() => userStore.user?.userRole === "Read")
  return {
    userRole,
    adminRole,
    readRole
  }
}

export function useUserInfo({ isAlwaysOpenExchange } = {}) {
  const userStore = useUserStore()
  var commonStore = useCommonStore()
  var appStore = useAppStore()
  var userid = ""
  if (userStore.user?.userId) {
    userid = userStore.user?.userId
  } else if (sessionStorage.getItem("useInfo")) {
    userid = JSON.parse(sessionStorage.getItem("useInfo")).userId
  }
  const userId = computed(() => userid)
  const userName = computed(() => userStore.user?.userName)
  const clientId = computed(() => userStore.user?.clientId)
  const productLine = localStorage.getItem("productline") === "amazon" ? "kroger" : localStorage.getItem("productline")
  var defaultCurrency = computed(() => userStore.user?.defaultCurrency)
  var dc = computed(() => commonStore.defaultCurrencyToMarket || userStore.user?.defaultCurrency)
  var userRole = computed(() => userStore.user?.userRole)
  var platform = computed(() => commonStore.platform || "amazon")
  var isRealTimeData = computed(() => userStore.user?.isRealTimeData)
  var perference = computed(() => {
    return userStore.userSettings?.randA?.toUpperCase() || "ROAS"
  }) //获取偏好

  var chooseAccounts = computed(() => appStore.chooseAccounts)

  var startDate = computed(() => appStore.dateRange?.startDate)
  var endDate = computed(() => appStore.dateRange?.endDate)
  var startCompar = computed(() => appStore.compareTime?.start)
  var endCompar = computed(() => appStore.compareTime?.end)
  var isExchange = computed(() => {
    if (isAlwaysOpenExchange) {
      return 1
    }
    return userStore.userSettings?.isExchange
  }) //是否汇率装换
  var availablePlatforms = computed(() => userStore?.user?.availablePlatforms || [])
  //判断是否是今天
  var isFeadToday = computed(() => {
    var isToday = false
    var latestDateMode = appStore.latestDateMode || "Real Time"
    if (startDate.value == endDate.value && latestDateMode.includes("Real Time") && startDate.value == dayjs().format("MM/DD/YYYY")) {
      isToday = true
    }
    return isRealTimeData.value && isToday
  })
  //数据开启campare
  var isCompare = computed(() => appStore.isCompare && appStore.showDateCompare) //是否显示campare数据
  var isDataCampare = computed(() => isCompare.value && !appStore.compareSummary)
  var isSummaryCompare = computed(() => isCompare.value) //是否显示summary数据
  var defaultDateFormat = computed(() => {
    var dateFormat = userStore.userSettings?.dateFormat || "0"
    return dateFormat == "0" ? "MM/DD/YYYY" : "DD/MM/YYYY"
  })
  return {
    userId,
    userName,
    userRole,
    clientId,
    availablePlatforms,
    productLine,
    defaultCurrency,
    dc,
    platform,
    isRealTimeData,
    perference,
    chooseAccounts,
    startDate,
    endDate,
    startCompar,
    endCompar,
    isExchange,
    isFeadToday,
    isCompare,
    isDataCampare,
    isSummaryCompare,
    defaultDateFormat
  }
}
export function PacvueSetDateRange({ startDate, endDate }) {
  var appStore = useAppStore()
  appStore.SET_DATERANGE({ startDate, endDate })
}
export function getMoneyCode(market) {
  var userInfo = useUserInfo()
  var dc = market || userInfo.dc.value
  return commonJS.marketCode(dc)
}
export function getDynamicMoneyCode(market) {
  var userInfo = useUserInfo()
  var moneyCode = computed(() => {
    var dc = market || userInfo.dc.value
    return commonJS.marketCode(dc)
  })
  return moneyCode
}
export function getQuery(isCompression = false) {
  let $route = useRoute()
  let routeQuery = commonJS.PacvueGetPageQuery({
    query: $route?.query?.query,
    isCompression
  })
  return routeQuery
}
export function setQuery(query, isCompression = false) {
  let routeQuery = commonJS.PacvueSetPageQuery({ query: query, isCompression })
  return routeQuery
}
export function getProIndexCompareState(type, value) {
  var colorMap = {
    gray: " #b2b2b2",
    green: "#28c76f",
    orange: "#fbaf46",
    red: "#ea5455"
  }
  let formatType = type.toLowerCase()
  value = value + ""
  let formatValue = Number(value.replace("%", ""))
  var ascRedField = ["acospercent", "cpapercent", "cpcpercent", "cpc", "cpm", "acos", "tacos", "cpa", "newtobrandacos", "newtobrandacospercent"]
  var ascGrayField = ["spendpercent", "spend", "cost", "imp", "impression", "impressions", "totalspendpercent", "campaignspend", "adspend"]

  let orderBy = "right"
  let colorStatus = "gray"
  if (!isNaN(formatValue)) {
    formatValue = PacvueKeepDigit(Number(formatValue), 2)
    if (ascRedField.indexOf(formatType) != -1) {
      //ASC RED
      if (formatValue > 0) {
        colorStatus = "red"
        orderBy = "up"
        if (formatValue < 50) {
          colorStatus = "orange"
        }
      } else if (formatValue < 0) {
        colorStatus = "green"
        orderBy = "down"
      } else {
        colorStatus = "gray"
      }
    } else if (ascGrayField.indexOf(formatType) != -1) {
      //ASC GRAY
      if (formatValue > 0) {
        orderBy = "up"
      } else if (formatValue < 0) {
        orderBy = "down"
      }
    } else {
      //ASC GREEN
      if (formatValue > 0) {
        colorStatus = "green"
        orderBy = "up"
      } else if (formatValue < 0) {
        colorStatus = "red"
        orderBy = "down"
        if (formatValue > -50) {
          colorStatus = "orange"
        }
      } else {
        colorStatus = "gray"
      }
    }
  }
  return {
    dir: orderBy,
    color: colorStatus,
    colorVal: colorMap[colorStatus]
  }
}

/**
 * @function {将带有透明度的rga转换为rga}
 * @param {*} _color
 * @param {*} _opacity
 * @returns
 */
export function rgbWithOpacity(_color, _opacity) {
  _opacity = _opacity || 0
  return RgbaTohex(hexToRGBA(_color, _opacity))
}
/**
 * @function {将十六进制颜色转换为rgba}
 * @param {*} _color
 * @param {*} _opacity
 * @returns
 */
function hexToRGBA(_color, _opacity) {
  let sColor = _color.toLowerCase()
  // 十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = "#"
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)))
    }
    return "rgba(" + sColorChange.join(",") + "," + _opacity + ")"
  }
  return sColor
}
/**
 * @function {将rgba转换十六进制颜色}
 * @param {*} color
 * @returns
 */
function RgbaTohex(color) {
  var values = color
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",")
  var a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
  return "#" + ("0" + r.toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + b.toString(16)).slice(-2)
}
/**
 * @function 监控元素大小变化
 * @param {} param0 el, callback
 */
export function watchElResize({ el, callback } = {}) {
  var resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      callback && callback(entry)
    }
  })
  resizeObserver.observe(el)
  return resizeObserver
}
export function formatTimeZone(value) {
  var userInfo = useUserInfo()
  value = value || userInfo.dc.value
  if (value == "America/Los_Angeles" || value == "US") {
    return "PST"
  } else if (value == "MST") {
    return "MST"
  } else if (value == "America/Eastern" || value == "CA") {
    return "EST"
  } else if (value == "America/Central") {
    return "CST"
  } else if (value == "Europe/Paris" || value == "SE" || value == "DE" || value == "FR" || value == "NL" || value == "UK" || value == "IT" || value == "BE") {
    return "CET"
  } else if (value == "Europe/London" || value == "ES") {
    return "GMT"
  } else if (value == "Asia/Dubai") {
    return "GST"
  } else if (value == "China/Beijing") {
    return "CST"
  } else if (value == "Asia/Tokyo" || value == "JP") {
    return "JST"
  } else if (value == "Australia/Sydney" || value == "AU") {
    return "AEST"
  } else if (value == "Asia/Kolkata" || value == "IN") {
    return "IST"
  } else if (value == "SA") {
    return "AST"
  } else if (value == "AE") {
    return "GST"
  } else if (value == "EG") {
    return "EST"
  } else if (value == "PL") {
    return "CEST"
  } else if (value == "TR") {
    return "GTB"
  } else if (value == "BR") {
    return "BRT"
  } else if (value == "MX") {
    return "CDT"
  } else if (value == "Europe/Amsterdam") {
    return "CEST"
  } else {
    return ""
  }
}

/**
 * @function 开启通信
 * @returns
 */
export function startLongCommunication(callBack) {
  let connection = null
  const initConnection = () => {
    const { getTokens } = pacvueToken
    let suffix = "com"
    if (window.location.hostname.indexOf(".cn") != -1) {
      suffix = "cn"
    }
    let prefix = ""
    if (window.location.hostname.indexOf("eu") != -1) {
      prefix = "-eu"
    }
    const url = "https://messageserver" + prefix + ".pacvue." + suffix + "/messageserver/messageHub"
    connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async function () {
          let token = getTokens()
          return token
        }
        //, transport: signalR.HttpTransportType.LongPolling
      })
      .configureLogging(signalR.LogLevel.Trace)
      .build()

    //.withAutomaticReconnect()
    connection.on("ReceiveMessage", function (message) {
      let json = {}
      try {
        json = JSON.parse(message)
      } catch (ex) {
        json = message
      }
      if (Object.prototype.toString.call(callBack) == "[object Function]") {
        callBack(json)
      }
    })

    connection
      .start()
      .then(function () {
        console.log(">>>>>>>>>start communication")
      })
      .catch(function (err) {
        // return console.error(err.toString())
      })

    // re-establish the connection if connection dropped
    connection.onclose(function () {
      if (connection.closeType != "custom") {
        setTimeout(connection.start(), 5000)
      }
    })
  }
  if (!window.signalR) {
    // 生成请求 signalR 的 script 标签
    const scriptEl = document.createElement("script")
    scriptEl.async = true
    scriptEl.src = "/js/websocket/signalr.min.js"
    scriptEl.type = "text/javascript"
    scriptEl.onload = () => initConnection()
    document.body.appendChild(scriptEl)
  } else {
    initConnection()
  }
  return connection
}

export function goUrlInterceptor({ path, query = {} } = {}) {
  var toPath = path
  var menuMap = {
    Profile: {
      path: "/Campaign/Profile",
      name: $t("Profile")
    },
    Adgroup: {
      path: "/Campaign/AdGroup",
      name: $t("Adgroup")
    },

    Portfolio: {
      path: "/Campaign/AmazonPortfolio",
      name: $t("amskey58")
    },
    Asin: {
      path: "/Campaign/Asin",
      name: $t("ASIN")
    },

    Campaign: {
      path: "/Campaign/Campaign",
      name: $t("amskey2283")
    },
    CampaignTag: {
      path: "/Campaign/CampaignTagging",
      name: $t("amskey2056")
    },
    KeywordTag: {
      path: "/Campaign/KeywordTagging",
      name: $t("amskey1570")
    },
    ASINTag: {
      path: "/Campaign/ASINTagging",
      name: $t("Asin Tagging")
    },
    AdgroupTag: {
      path: "/Campaign/AdgroupTagging",
      name: $t("AdGroup Tag")
    },
    SBAds: {
      path: "/Campaign/SbAds",
      name: $t("Ad")
    },
    SearchTerm: {
      path: "/Campaign/Query",
      name: $t("key679")
    },
    Targeting: {
      path: "/Campaign/Keyword",
      name: $t("Targeting")
    }
  }
  if (path == "/Campaign/Detail") {
    var menuValue = query.mode || query.menu
    if (menuMap[menuValue]) {
      if (menuMap[menuValue].path == "/Campaign/SbAds") {
        toPath = "/Campaign/Detail"
      } else {
        toPath = menuMap[menuValue].path + "/Detail"
      }
    }
  }
  return toPath
}
export function goUrl({ path, query, storeType = "url", queryName = "query", isCompression = false }) {
  path = goUrlInterceptor({ path: path || "", query })
  if (path.indexOf("/Budget/") == 0 && path != "/Budget/BudgetDashboard") {
    // GetBudgetSetFiscalYear(function (startMonthStr, endMonthStr) {
    //   window.location.href = u;
    // });
    window.open(path + "?" + queryName + "=" + setQuery(query, isCompression))
    // router.push({
    //   path: path,
    //   query: query
    //     ? {
    //         query: setQuery(query)
    //       }
    //     : {}
    // })
    return
  }
  window.open(path + "?" + queryName + "=" + setQuery(query, isCompression))
  // router.push({
  //   path: path,
  //   query: query
  //     ? {
  //         query: setQuery(query)
  //       }
  //     : {}
  // })
}
export function createMoreActionItem({ label, value, icon, type, className, tip, dynamicTip, dataCy, size, groupVal, groupName } = {}) {
  value = value || label.replace(/\s/g, "")
  return {
    label: label,
    value: value,
    type: type || "default",
    icon: icon,
    tip: tip, // || label,
    className,
    dynamicTip,
    size,
    dataCy,
    onClick: function ({ rowData, column }) {},
    groupName,
    groupVal: groupVal || groupName
  }
}
// orderedUnits: 'Ordered Units',
// totalRevenue: 'Total Revenue',
// y轴文本格式化方法

export const yLabelFormatter = (type, val, dc) => {
  const userStore = useUserStore()
  if (type === "OrderPrice" || type === "CPC" || type === "CPA" || type === "T-ROAS") {
    return common.formatMoney1(val, common.marketCode(dc || userStore.user?.defaultCurrency))
  } else if (
    type === "shippedCogs" ||
    type === "Total Revenue" ||
    type === "Total Ad Sales" ||
    type === "Sales" ||
    type === "NewToBrandSales" ||
    type === "Ad Sales" ||
    type === "Organic Revenue" ||
    type === "Total Sales" ||
    type === "Organic Sales" ||
    type === "Total Media Sales" ||
    type === "ASP"
  ) {
    return common.formatSalesMoney(val, common.marketCode(dc || userStore.user?.defaultCurrency))
  } else if (
    type === "Impression" ||
    type === "SaleUnits" ||
    type === "Click" ||
    type === "NewToBrandOrders" ||
    type === "Conversion" ||
    type === "NewToBrandUnitsOrdered" ||
    type === "Ordered Units"
  ) {
    return common.toThousandsx(val)
  } else if (
    type === "ACOS" ||
    type === "CTR" ||
    type === "CVR" ||
    type === "NewToBrandOrderRate" ||
    type == "NewToBrandOrdersPercentage" ||
    type == "NewToBrandSalesPercentage" ||
    type == "NewToBrandUnitsOrderedPercentage" ||
    type == "T-ACOS" ||
    type === "netPpm"
  ) {
    return common.formatPercent(val)
  } else if (type === "ROAS") {
    return common.formatMoneyRoas(val, common.marketCode(dc || userStore.user?.defaultCurrency))
  } else if (type === "Spend" || type === "Ad Spend") {
    return common.formatSpendsMoney(val, common.marketCode(dc || userStore.user?.defaultCurrency))
  } else {
    return val
  }
}

export function getWeekStartSun(d, market, dateFormat) {
  var marketCode = market || "US"
  // var time = dayjs(d, dateFormat||marketDateFormat(marketCode));
  // return time.week();
  var time = dayjs(d, dateFormat || commonJS.marketDateFormat(marketCode))
  var FirstOfTime = time.startOf("week")
  var FirstDayOfYear = dayjs(FirstOfTime)
  FirstDayOfYear = FirstDayOfYear.date(4) //4号作为分界点
  FirstDayOfYear = FirstDayOfYear.month(0)
  var interval = 0
  var FirstDayOfWeek = FirstDayOfYear.startOf("week") //第一周的第一天日期
  var days = FirstOfTime.diff(FirstDayOfWeek, "days")
  days += FirstDayOfYear.day()
  let num = Math.floor(days / 7) + 1 - interval
  return num
}
/**
 * @function 获取path信息
 * @returns
 */
export function PacvueGetPathInfo() {
  var pathname = window.location.pathname
  var pathnameList = pathname.split("/").slice(1)
  var pathnameSize = pathnameList.length
  var firstMenu = pathnameList[0] || ""
  var lastMenu = pathnameList[pathnameSize - 1] || ""
  return {
    firstMenu: firstMenu,
    lastMenu: lastMenu,
    firstMenuLower: firstMenu.toLowerCase(),
    lastMenuLower: lastMenu.toLowerCase(),
    pathname: pathname,
    pathnameLower: pathname.toLowerCase()
  }
}
export function equalJSON(obj1, obj2) {
  var isEqual = true
  var obj1Type = Object.prototype.toString.call(obj1)
  var obj2Type = Object.prototype.toString.call(obj2)
  var normalTyps = ["[object String]", "[object Number]", "[object Undefined]", "[object Null]"]
  if (normalTyps.includes(obj1Type) || normalTyps.includes(obj2Type)) {
    return obj1 == obj2
  }
  if (obj1Type != obj2Type) {
    return false
  } else if (obj1Type == "[object Array]") {
    if (obj1.length != obj2.length) {
      return false
    }
  } else if (obj1Type == "[object Object]") {
    var obj1Names = Object.keys(obj1)
    var obj2Names = Object.keys(obj2)
    if (obj1Names.length != obj2Names.length) {
      return false
    }
  }
  if (Object.keys(obj2).length > Object.keys(obj1).length) {
    ;[obj1, obj2] = [obj2, obj1]
  }
  for (var key in obj1) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2.hasOwnProperty(key)) {
      var value1 = obj1[key]
      var value2 = obj2[key]
      if (value1 != value2) {
        var value1Type = Object.prototype.toString.call(value1)
        var value2Type = Object.prototype.toString.call(value2)
        if (value1Type != value2Type) {
          //类型不一样
          isEqual = false
          break
        } else if (value1Type == "[object Array]") {
          if (value1.length != value2.length) {
            isEqual = false
            break
          }
          if (!equalJSON(value1, value2)) {
            isEqual = false
            break
          }
        } else if (value1Type == "[object Object]") {
          if (!equalJSON(value1, value2)) {
            isEqual = false
            break
          }
        } else {
          isEqual = false
          break
        }
      }
    } else {
      isEqual = false
      break
    }
  }
  return isEqual
}
/**
 * @function {保留几位小数}
 * @param {保留小数的数字} value
 * @param {保留小数的位数} digitCount
 */
export function PacvueKeepDigit(value, digitCount, isIntercept) {
  if (String(value).trim() === "") return ""
  var numVal = Number(value)
  digitCount = digitCount || 0
  if (isNaN(numVal)) {
    return 0
  }
  var divisor = Math.pow(10, digitCount)
  if (isIntercept) {
    return Math.floor(numVal + "e" + digitCount) / divisor
    //return Math.floor(numVal * divisor) / divisor
  } else {
    return Math.round(numVal + "e" + digitCount) / divisor
    // return Math.round(numVal * divisor) / divisor
  }
}
/**
 * @function {} 设置临时变量参数
 * @returns
 */
export function usePageTempParamsStore() {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var platform = userInfo.platform.value
  var prev = platform + "_" + userId + "_"
  var setToPageTempParams = function (val) {
    sessionStorage.setItem(prev + "pageTemp", JSON.stringify(val))
  }
  var getToPageTempParams = function () {
    var val = sessionStorage.getItem(prev + "pageTemp")
    return JSON.parse(val) || null
  }
  var deleteToPageTempParams = function () {
    sessionStorage.removeItem(prev + "pageTemp")
  }
  return {
    setToPageTempParams,
    getToPageTempParams,
    deleteToPageTempParams
  }
}
export function useLocalStore() {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var platform = userInfo.platform.value
  var prev = platform + "_" + userId + "_"
  var setLocalStore = (key, val) => {
    localStorage.setItem(prev + key, JSON.stringify(val))
  }
  var getLocalStore = (key) => {
    try {
      var val = localStorage.getItem(prev + key)
      return JSON.parse(val) || null
    } catch (ex) {
      return null
    }
  }
  var delLocalStore = (key) => {
    localStorage.removeItem(prev + key)
  }
  return {
    setLocalStore,
    getLocalStore,
    delLocalStore
  }
}
export function PacvueHasPermission() {
  var userInfo = useUserInfo()
  var userRole = userInfo.userRole.value
  const editType = usePacvuePermission({ userRole })
  if (editType == "Read") {
    PacvueMessageBox({
      title: "Tip",
      message: $t("key1341"),
      type: "warning"
    })
    return false
  }
  return true
}
export function useFormatInput({ callback } = {}) {
  var formatInt = (val, fieldKey) => {
    var realVal = val
    if (val) {
      realVal = parseInt(val)
    }
    callback && callback({ key: fieldKey, value: realVal })
    return realVal
  }
  return { formatInt }
}
export function usePacvueLocalStore({ customPrev = "" } = {}) {
  var userInfo = useUserInfo()
  let realPrev = PERSIST_KEY_PRE + (customPrev ? customPrev + "_" : "")
  return {
    setTagging: function (val, tagname = "") {
      var userId = userInfo.userId.value
      var prev = realPrev + userId + "_"
      localStorage.setItem(prev + "pacvue_" + tagname + "tagging", JSON.stringify(val))
    },
    sethassubTagging: function (index, val) {
      var userId = userInfo.userId.value
      var prev = realPrev + userId + "_"
      var subtagArr = ["adgroup", "campaign", "asin"]
      localStorage.setItem(prev + "pacvue_hassub" + subtagArr[index] + "tagging", JSON.stringify(val))
    },
    gethassubTagging: function (tagname = "") {
      var userId = userInfo.userId.value
      var prev = realPrev + userId + "_"
      var adgroupsubtaggingStr = localStorage.getItem(prev + "pacvue_hassub" + tagname + "tagging")
      var subtagggingAdgroupSelectedArray = {}
      if (adgroupsubtaggingStr) {
        subtagggingAdgroupSelectedArray = JSON.parse(adgroupsubtaggingStr) || {}
      }
      return subtagggingAdgroupSelectedArray
    },
    setItem(key, value) {
      var userId = userInfo.userId.value
      var prev = realPrev + userId + "_"
      localStorage.setItem(prev + "pacvue_" + key, JSON.stringify(value))
    },
    delItem(key) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var uniqueId = realPrev + prev + "pacvue_" + key
      localStorage.removeItem(uniqueId)
    },
    getItem(key) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var uniqueId = realPrev + prev + "pacvue_" + key
      var realVal = []
      var valStr = localStorage.getItem(uniqueId)
      if (valStr) {
        realVal = JSON.parse(valStr) || []
      }
      return realVal
    }
  }
}
export function strCapitalize(str) {
  str = str ? str : ""
  if (Object.prototype.toString.call(str) == "[object String]") {
    str = str.charAt(0).toUpperCase() + (str.slice(1) || "").toLowerCase()
  }
  return str
}
export function usePacvueCustomBread({ name = "", value } = {}) {
  const BreadTitle = defineComponent({
    render: function () {
      return h("div", {
        class: ["font-bold", ""],
        style: {
          fontSize: "20px",
          "white-space": "nowrap",
          overflow: "hidden",
          "text-overflow": "ellipsis"
        },
        innerHTML: escape(`${name ? name + ":" : ""}${value}`)
      })
    }
  })
  const { updateBreadcrumbLeft } = inject("breadcrumb")
  updateBreadcrumbLeft(BreadTitle)
  onUnmounted(() => updateBreadcrumbLeft(null))
}
export function PacvueGetHost() {
  var protocol = location.protocol
  var host = location.host
  return protocol + "//" + host
}
export function jumpToRetailDashboard(appname, options) {
  var referrer = PacvueGetHost()
  var ApiName = appname || "retailer-test"
  var mode = import.meta.env.MODE || "development"
  if (appname == "retailer-test" && mode.includes("pro")) {
    ApiName = "retailer"
  }
  var hostname = window.location.hostname.toLowerCase()
  options = options || {}
  var redirect = options.redirect
  var tagId = options.tagId
  var parentTagId = options.parentTagId
  var extraStr = `${redirect ? "&redirect=" + redirect : ""}${tagId ? "&tagId=" + tagId : ""}${parentTagId ? "&parentTagId=" + parentTagId : ""}`
  var params = common.encrypt(
    JSON.stringify({
      redirect: redirect,
      tagId: tagId,
      parentTagId: parentTagId
    })
  )
  getCrossAuthCode().then((data) => {
    // common.encrypt
    if (hostname == "localhost") {
      window.open(`http://localhost:3001?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`)
    } else if (hostname.lastIndexOf("dspwinner") != -1) {
      ApiName = ApiName == "product" ? "app" : ApiName
      window.open(`https://${ApiName}.dspwinner.${domain}?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`, self)
    } else {
      var domain = window.location.href.indexOf("cn") != -1 ? "cn" : "com"
      if (window.location.href.indexOf("-eu") !== -1) {
        ApiName = ApiName + "-eu"
      }
      if (appname == "dsa") {
        domain = "com"
      }
      // window.open(`http://localhost:3001?authcode=${data}&query=${common.encrypt(localStorage.getItem('productline'))}`)
      window.open(`https://${ApiName}.pacvue.${domain}?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`)
      return
    }
    /*if (hostname == 'localhost') {
      window.open(`http://localhost:8080?authcode=${data}&referrer=${referrer}${extraStr}`)
    } else*/
    // if (enviroment == 'test') {
    //   window.open('/ShareCenter/index')
    //   // window.open(`https://${ApiName}test.pacvue.${domain}?authcode=${data}&referrer=${referrer}${extraStr}`)
    // } else {
    //   window.open(`https://${ApiName}.pacvue.${domain}?authcode=${data}&referrer=${referrer}${extraStr}`)
    // }
  })
}
// function getPacvueEnvironment() {
//   var hostname = window.location.hostname.toLowerCase()
//   if (hostname.indexOf("dev") != -1 || hostname.indexOf("test") != -1 || hostname == "localhost") {
//     return "test"
//   } else if (hostname.indexOf("demo") != -1) {
//     return "demo"
//   } else {
//     return "pro"
//   }
// }
export function copyJSON(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}
export async function PacvueGetMaxDateTime({ query, isRequired = false } = {}) {
  var advertisingStore = useAdvertisingStore()
  var userInfo = useUserInfo()
  var isFeadToday = userInfo.isFeadToday.value
  var isFead = query.IsFead ?? query.isFead
  if (isFead !== undefined) {
    isFeadToday = isFead
  }
  var result = { start: query.Start ?? query.start, end: query.End ?? query.end }
  if ((isFeadToday || isRequired) && result.start && result.end) {
    result = await advertisingStore.PacvueGetMaxDateTime(query)
  }
  return result
}
/**
 * 下载报表需要token验证
 *
 * @param {*} url
 * @param {*} params
 */
export function formSubmitWithToken(url, params, method = "post", dataType, filterSelf = false, deep, callback) {
  params = params || {}
  var filter = params["filter"]
  if (Object.prototype.toString.call(filter) == "[object Array]") {
    if (!filterSelf) {
      var filterJson = JSON.stringify(filter)
      delete params["filter"]
      params.filterJson = filterJson
    }
  }
  var aParams = [] // 用来存储“名-值”对的数组
  var formParams = ""
  if (dataType == "form-params") {
    formParams = $.param(params)
  } else if (dataType != "json") {
    for (var key in params) {
      var value = params[key]
      // if (deep) {
      //   formItemRecursion(value, key, aParams, "push-qs")
      //   continue
      // }
      if (Object.prototype.toString.call(value) == "[object Array]") {
        value.forEach((x, i) => {
          var nkey = encodeURIComponent(key)
          nkey += `[${i}]=`
          nkey += encodeURIComponent(x)
          aParams.push(nkey)
        })
      } else if (Object.prototype.toString.call(value) == "[object Object]") {
        for (var childKey in value) {
          var value2 = value[childKey]
          if (Object.prototype.toString.call(value2) == "[object Array]") {
            value2.forEach((x, i) => {
              var nkey2 = encodeURIComponent(key)
              nkey2 += `[${childKey}][${i}]=`
              nkey2 += encodeURIComponent(x)
              aParams.push(nkey2)
            })
          } else if (Object.prototype.toString.call(value2) == "[object Object]") {
            for (var childCell in value2) {
              var objkey2 = encodeURIComponent(key)
              objkey2 += `[${childKey}][${childCell}]=`
              objkey2 += encodeURIComponent(value2[childCell])
              aParams.push(objkey2)
            }
          } else if (Object.prototype.toString.call(value2) == "[object String]") {
            var objkey = encodeURIComponent(key)
            objkey += `[${childKey}]=`
            objkey += encodeURIComponent(value2)
            aParams.push(objkey)
          }
        }
      } else {
        var sParam = encodeURIComponent(key)
        sParam += "="
        sParam += encodeURIComponent(params[key])
        aParams.push(sParam)
      }
    }
    formParams = aParams.join("&")
  } else {
    formParams = JSON.stringify(params || {})
  }

  // 原生请求
  var xhr = new XMLHttpRequest()
  var methods = "post"
  if (method == "notoken") {
    methods = "post"
  } else {
    methods = method
  }
  xhr.open(methods, url, true) //get请求，请求地址，是否异步
  if (dataType != "json") {
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
  } else {
    xhr.setRequestHeader("Content-type", "application/json")
  }
  if (method == "notoken") {
    console.log("notoken")
  } else {
    let token = "Bearer " + getTokens()
    xhr.setRequestHeader("Authorization", token)
  }
  xhr.responseType = "blob"
  xhr.onload = function (evt) {
    if (this.status == 200) {
      var data = this.response

      var blob = new Blob([data])
      var a = document.createElement("a")
      var fileurl = window.URL.createObjectURL(blob)
      a.href = fileurl
      //设置文件名称
      var fileName = xhr.getResponseHeader("content-disposition").split(";")[1].split("=")[1] || ""
      fileName = fileName.replace(/\"/g, "")
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(fileurl)

      if (callback && Object.prototype.toString.call(callback) === "[object Function]") {
        callback()
      }
    }
  }
  xhr.send(formParams)
}
export function hasMenuPermissions({ menuName, pathname }) {
  var appStore = useAppStore()
  if (pathname) {
    return appStore.menuMap[pathname] ? true : false
  }
  var menuList = appStore.menu || []
  if (menuName) {
    var isExist = recuisionMenu({ menuList, menuName })
    return isExist
  }
  return false
}
function recuisionMenu({ menuList, menuName }) {
  for (var i = 0, menuSize = menuList.length; i < menuSize; i++) {
    var menuItem = menuList[i]
    var children = menuItem.children || []
    if (children.length) {
      var isExist = recuisionMenu({ menuList: children, menuName })
      if (isExist) {
        return true
      }
    } else if (menuItem.menuName == menuName) {
      return true
    }
  }
  return false
}
export function PacvueGetProIndexOrderBy({ searchQuery }) {
  var multiSortList = unref(searchQuery).multiSortList || []
  var commonProIndexs = [
    "Impression",
    "Click",
    "CTR",
    "Spend",
    "CPC",
    "CPA",
    "CVR",
    "Conversion",
    "SaleUnits",
    "Sales",
    "ACOS",
    "TACOS",
    "ROAS",
    "OrderPrice", //AOV
    "OtherSales",
    "OtherSalesPercent",
    "NewToBrandSalesPercentage",
    "NewToBrandSales",
    "NewToBrandOrderRate",
    "NewToBrandOrdersPercentage",
    "NewToBrandOrders"
  ]
  var isNoMatch = false
  var sortByList = []
  multiSortList.forEach((sortInfo) => {
    var sortData = sortInfo.sortData
    var sortType = sortInfo.sortType || "desc"
    if (!commonProIndexs.includes(sortData)) {
      isNoMatch = true
    }
    sortByList.push({ value: sortData, sort: sortType })
  })
  if (!isNoMatch) {
    return sortByList
  }
  return []
}
/**
 *
 * @param {替换的字符串或者数组对象} value
 * @param {如果替换的是数组对象，替换字段的key} key
 * @returns
 */
export function PacvueReplaceKws({ value, key } = {}) {
  var reg = /["“”]/g
  var type = Object.prototype.toString.call(value)
  if (type == "[object String]") {
    return value.replace(reg, "")
  } else if (type == "[object Array]") {
    return value.map((item) => {
      if (typeof item == "string") {
        return value.replace(reg, "")
      } else if (typeof item == "object") {
        return {
          ...item,
          [key]: item[key]?.replace(reg, "")
        }
      }
    })
  }
  return value
}
export function PacvueGetNumber(val, digitCount = 2) {
  var numReg = /\d+([.,]\d+)?/
  var numVal = val || 0
  if (typeof val == "string") {
    var matchList = val.match(numReg) || []
    var matchVal = (matchList[0] || 0).toString().replace(",", ".")
    numVal = parseFloat(matchVal || 0)
  }
  //需要增加保留两位小数
  numVal = PacvueKeepDigit(Number(numVal), digitCount)
  return numVal
}

export function PacvueUUid(size = 21, url) {
  let urlAlphabet = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict"
  if (url) {
    urlAlphabet = url
  }
  let id = ""
  let i = size
  while (i--) {
    id += urlAlphabet[(Math.random() * urlAlphabet.length) | 0]
  }
  return id
}
export function PacvueCircularJson({ json = {}, jsonType = "json" } = {}) {
  const getCircularReplacer = () => {
    const seen = new WeakSet()
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return
        }
        seen.add(value)
      }
      return value
    }
  }
  var Replacer = getCircularReplacer()
  var jsonStr = JSON.stringify(json, Replacer)
  return jsonType == "json" ? JSON.parse(jsonStr) : jsonStr
}
export function PacvueJsonDiff(obj1, obj2, depMap) {
  var obj1Type = Object.prototype.toString.call(obj1)
  var obj2Type = Object.prototype.toString.call(obj2)
  var normalTyps = ["[object String]", "[object Number]", "[object Undefined]", "[object Null]"]
  depMap = depMap || {}
  if (normalTyps.includes(obj1Type) || normalTyps.includes(obj2Type)) {
    return (depMap = { ...depMap, ...obj1 })
  }
  for (var key in obj1) {
    var value1 = obj1[key]
    // eslint-disable-next-line no-prototype-builtins
    if (obj2.hasOwnProperty(key)) {
      var value2 = obj2[key]
      if (value1 != value2) {
        var value1Type = Object.prototype.toString.call(value1)
        if (value1Type == "[object Array]") {
          PacvueJsonDiff(value1, value2, depMap)
        } else if (value1Type == "[object Object]") {
          PacvueJsonDiff(value1, value2, depMap)
        } else {
          depMap[key] = value1
        }
      }
    } else {
      depMap[key] = value1
    }
  }
  return depMap
}
export function PacvueOrderByFieldCallBack({ orderByField, split = ",", innerSplit = " ", callback } = {}) {
  if (Object.prototype.toString.call(orderByField) == "[object String]") {
    var orderFieldList = orderByField.split(split)
    var realOrderBy = []
    orderFieldList.forEach((orderItem) => {
      var orderBy = orderItem.split(innerSplit)
      var orderField = orderBy[0]
      var orderDir = orderBy[1] || ""
      if (callback) {
        orderField = callback({ orderField }) || orderByField
      }
      realOrderBy.push(orderField + (orderDir ? innerSplit : "") + orderDir)
    })
    return realOrderBy.join(split)
  } else {
    return orderByField
  }
}
export function PacvueIsProIndex(datax) {
  //指标排序需要重构
  var proIndexs = [
    "Impression",
    "Click",
    "CTR",
    "Spend",
    "CPC",
    "CPA",
    "CVR",
    "Conversion",
    "SaleUnits",
    "Sales",
    "ACOS",
    "TACOS",
    "ROAS",
    //额外total参数
    "OtherSales",
    "OtherSalesPercent",
    "OrderPrice",
    "ASP",
    "NewToBrandOrders",
    "NewToBrandSales",
    "NewToBrandUnitsOrdered",
    "NewToBrandOrdersPercentage",
    "NewToBrandOrderRate",
    "NewToBrandSalesPercentage",
    "NewToBrandUnitsOrderedPercentage",
    //
    "VCPM",
    "ViewImpression",
    "brandedSearch",
    //其他平台参数
    "clicks",
    "impressions",
    "ctr",
    "spend",
    "cpc",
    "acos",
    "roas",
    "sales",
    "otherSales",
    "saleUnits",
    "otherSaleUnits",
    "cvr",
    "cpa",
    "impressionShare",
    "lostImpressionShareByBudget",
    "lostImpressionShareByAdRank",
    "acosBenchmark",
    //SameSKU
    "SameSkuSales",
    "SameSkuSaleUnits",
    "SameSkuConversions",
    "SameSkuROAS",
    "SameSkuACOS",
    "SameSkuCPA",
    "SameSkuCVR",
    "dailyBudget", //使用指标组件渲染
    "cumulativeReach",
    "avgImpressionsFrequency",
    "brandedSearch"
  ]
  return proIndexs.includes(datax)
}
/**
 *
 * @param {需要驼峰化的字符串} str String
 * @param {分隔符} split String
 * @returns String
 */
export function PacvueStrHump(str, split = "-") {
  str = str ? str : ""
  if (Object.prototype.toString.call(str) == "[object String]") {
    var strList = str.split(split)
    var capStrList = strList.map((item) => {
      return strCapitalize(item)
    })
    return capStrList.join("")
  }
  return str
}
const reqCancelMap = {}
export function requestData(options, { cacheKey, isAutoCancel = true, cacheKeyPrefix = "" } = {}) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  var req = request({
    ...options,
    cancelToken: source.token,
    _cancel: source.cancel
  })
  if (cacheKey && isAutoCancel) {
    let realCacheKey = `${cacheKeyPrefix ? cacheKeyPrefix + "_" : ""}${cacheKey}`
    let prevCancel = reqCancelMap[realCacheKey]
    typeof prevCancel == "function" && prevCancel()
    reqCancelMap[realCacheKey] = source.cancel
    return new Promise((resolve, reject) => {
      req
        .then((res) => resolve(res))
        .catch((ex) => reject(ex))
        .finally(() => {
          delete reqCancelMap[realCacheKey]
        })
    })
  }
  req.cancel = source.cancel
  return req
}
/**
 * @function 将日期装换为通用的日期格式,如果sourceDateFormat，desDateFormat，在跟随market日期格式来
 * @param {Object} [option={}]
 * @param {String|Number} option.date 需要格式化的日期对象
 * @param {String} option.dim 日期类型Day|Week|Month|Quarter|Year
 * @param {String} option.sourceDateFormat 源日期格式
 * @param {String} option.desDateFormat 源日期格式
 * @param {String} option.desDateFormat 导出的日期格式
 * @param {String} option.marketCode 默认的日期格式市场为US,
 * @param {String} option.defaultUserMarketCode 用户的市场dc
 * @returns String
 */
export function PacvueFormatDateByDim({ date, dim, sourceDateFormat, desDateFormat, marketCode = "US", defaultUserMarketCode } = {}) {
  var userInfo = useUserInfo()
  var dc = defaultUserMarketCode ?? userInfo.dc.value
  return commonJS.PacvueTransfromDate({ date, dim, sourceDateFormat, desDateFormat, marketCode, defaultUserMarketCode: dc })
}
export function PacvueGetLanguage() {
  var elementLocale = "en"
  if (localStorage.getItem("locale") === "ZH") {
    elementLocale = "zh-cn"
  } else if (localStorage.getItem("locale") === "JA") {
    elementLocale = "ja"
  }
  return elementLocale
}
export function PacvueXValueFormat({ dim = "ytd", aData, ignoreYear, isUseServerFormat = false, dataTimeKey = "DateTime" } = {}) {
  var elementLocale = PacvueGetLanguage()
  dayjs.locale(elementLocale)
  var weekArr = {
    "Mon.": $t("Monday"),
    "Tues.": $t("Tuesday"),
    "Wed.": $t("Wednesday"),
    "Thu.": $t("Thursday"),
    "Fri.": $t("Friday"),
    "Sat.": $t("Saturday"),
    "Sun.": $t("Sunday")
  }
  let year = aData.year ?? aData.Year
  if (year === 0 && !ignoreYear) {
    return "--"
  }
  var xValue = aData[dataTimeKey] ?? aData["ReportDateTime"]
  var dateReg = /^(\d{4})-(\d{2})-(\d{2})/
  var dateFormat = undefined
  if (dateReg.test(xValue)) {
    dateFormat = "YYYY-MM-DD"
  }
  var dayValue = dayjs(xValue, dateFormat)
  switch (dim) {
    case "ytd":
      return ""
    case "date":
      if (dayValue.isValid()) {
        return dayValue.format("ddd,MMM-DD-YYYY")
      }
      return aData.DaysShow
    case "week":
      let weekDateTimeShow = aData["weekDateTimeShow"] ?? aData["WeekDateTimeShow"] ?? aData["ReportDateTime"]
      dayValue = dayjs(weekDateTimeShow, dateFormat)
      if (dayValue.isValid()) {
        var formatDateStr = dayValue.format("MM/DD/YYYY")
        //如果是周的时候需要特殊处理
        if (dim == "week" && isUseServerFormat) {
          var desDateFormat = commonJS.marketDateFormat("US")
          var sourceDateFormat = "MM/DD/YYYY"
          return $t("amskey2584", [aData.week ?? aData.Week]) + "," + dayjs(formatDateStr, sourceDateFormat).format(desDateFormat)
        }
        return $t("amskey2584", [commonJS.PacvueGetWeekStartSun(formatDateStr)]) + "," + dayValue.format("MMM-DD-YYYY")
      }
      return aData.weekShow ?? aData.WeekShow
    case "month":
      let year = aData.year ?? aData.Year
      if (year == 0 && !ignoreYear) {
        return "--"
      }
      if (dayValue.isValid()) {
        return dayValue.format("MMM-YYYY")
      }
      return aData.monthShow ?? aData.MonthShow
    case "quarter":
      if (dayValue.isValid()) {
        return dayValue.format(`${$t("q{0}", ["Q"])}-YYYY`).toUpperCase()
      }
      return aData.quarterShow ?? aData.QuarterShow
    case "year":
      return aData.year ?? aData.Year
    case "dayOfWeek":
      return weekArr[aData.weekDay ?? aData.WeekDay]
  }
}
export function PacvueCreateFilterStoreKey({ storeKey } = {}) {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var prev = PERSIST_KEY_PRE + userId + "_"
  return prev + storeKey
}
export function PacvueGetPlacementInfo({ placement } = {}) {
  var topofsearcharr = [
    "Arama Sonuçlarının En Üstü",
    "En haut de la recherche",
    "Início da pesquisa",
    "Inizio della ricerca",
    "Top de Búsqueda",
    "Top of Search",
    "Top of Search on-Amazon",
    "top of search on-amazon",
    "検索のトップ",
    "Oben in den Suchergebnissen",
    "Principio de la búsqueda",
    "Na górze strony wyników wyszukiwania",
    "Överst på sökning",
    "Top de Búsqueda",
    "Top van Zoeken",
    "top of search"
  ]
  // var otherpagearr = []
  var detailpagearr = ["Detail Page on-Amazon", "detail page on-amazon", "Detail Page", "detail page"]
  var restofsearch = [
    "Search on-amazon",
    "Search on Amazon",
    "search on-amazon",
    "rest of search",
    "Altri posizionamenti",
    "Andere plaatsingen",
    "Andere Platzierungen",
    "Andra placeringar",
    "Autres placements",
    "Diğer Yerleştirmeler",
    "Inne miejsca docelowe",
    "Other on-Amazon",
    "other on-amazon",
    "Other Placements",
    "Otras colocaciones",
    "Otros anuncios",
    "Outras colocações",
    "その他の広告枠",
    "other placement"
  ]
  let unclassified = ["Unclassified"]
  var gatewayOnAmazon = ["Gateway on-Amazon"]
  var offAmazon = ["Off Amazon", "off amazon"]
  var homepageOnAmazon = ["Homepage on-Amazon", "home page"]
  let siteAmazonBusiness = ["Site Amazon Business"]
  let amazonBusiness = ["Amazon Business"]
  var placementGroupList = [
    {
      groupName: $t("amskey458"),
      groupType: "Top of Search",
      dataList: topofsearcharr
    },
    {
      groupName: $t("amskey459"),
      groupType: "Detail Page",
      dataList: detailpagearr
    },
    {
      groupName: $t("amskey460"),
      groupType: "Rest of Search",
      dataList: restofsearch
    },
    {
      groupName: $t("Gateway on-Amazon"),
      groupType: "Gateway on-Amazon",
      dataList: gatewayOnAmazon
    },
    {
      groupName: $t("Off Amazon"),
      groupType: "Off Amazon",
      dataList: offAmazon
    },
    // {
    //   groupName: $t("amskey461"),
    //   groupType: "Other",
    //   dataList: otherpagearr
    // },
    {
      groupName: $t("amskey3153"),
      groupType: "Homepage on-Amazon",
      dataList: homepageOnAmazon
    },
    {
      groupName: $t("Unclassified"),
      groupType: "Unclassified",
      dataList: unclassified
    },
    {
      groupName: $t("Site Amazon Business"),
      groupType: "Site Amazon Business",
      dataList: siteAmazonBusiness
    },
    {
      groupName: $t("Amazon Business"),
      groupType: "Amazon Business",
      dataList: amazonBusiness
    }
  ]
  var groupInfo = placementGroupList.find((placementInfo) => {
    var dataList = placementInfo.dataList || []
    if (dataList.includes(placement)) {
      return true
    }
    return false
  })
  return groupInfo ?? { groupName: $t("amskey460"), groupType: "Rest of Search", dataList: [placement] }
}

export function PacvuePreModerationMessageLength(res) {
  let messageLen = 0
  if (res.imageComponents && res.imageComponents.length > 0) {
    res.imageComponents.forEach((item) => {
      if (item.preModerationStatus == "REJECTED") {
        messageLen++
      }
    })
  }
  if (res.textComponents && res.textComponents.length > 0) {
    res.textComponents.forEach((item) => {
      if (item.preModerationStatus == "REJECTED") {
        messageLen++
      }
    })
  }
  if (res.videoComponents && res.videoComponents.length > 0) {
    res.videoComponents.forEach((item) => {
      if (item.preModerationStatus == "REJECTED") {
        messageLen++
      }
    })
  }
  return messageLen
}
export function PacvueHandleDownload({ fetch, query = {}, fileName, isAsync = true, suffix = "xlsx" }) {
  fetch(query).then((res) => {
    if (!isAsync) {
      const today = dayjs().format("YYYY-MM-DD")
      commonJS.DownLoad(res, fileName + `${today}` + "." + suffix)
    }
  })
}
/**
 * function {数组多属性排序}
 * @param {排序的数据} array
 * @param {属性对象,Array<{name:'排序字段',sort:排序方法，orderDir:'排序方向（asc,desc）'}>} props
 * @param {*} sortDir
 * @returns
 */
export function PacvueArrayMultiPropSort(array, props) {
  var getSortDir = function (val1, val2, sortFn, sortDir) {
    if (Object.prototype.toString.call(sortFn) == "[object Function]") {
      return sortFn(val1, val2)
    }
    if (val1 > val2) {
      return sortDir != "desc" ? 1 : -1
    } else if (val1 < val2) {
      return sortDir != "desc" ? -1 : 1
    } else {
      return 0
    }
  }
  var propsSize = props.length
  var filterArray = array.sort(function (a, b) {
    for (var i = 0; i < propsSize; i++) {
      var prop = props[i].name
      var sortFn = props[i].sort //排序方式
      var sortDir = props[i].orderDir //排序方向asc ,desc
      var val1 = a[prop]
      var val2 = b[prop]
      var result = getSortDir(val1, val2, sortFn, sortDir)
      if (result != 0) {
        return result
      }
    }
    return 0
  })
  return filterArray
}
export function getUrlParams(url) {
  url = url || location.href
  // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
  let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/gi
  let result = {}
  url.replace(pattern, ($, $1, $2) => {
    result[$1] = $2
  })
  return result
}
export function getErrorData({
  selectDataList = [],
  createUniqueId = (rowData) => {
    return rowData["campaignId"]
  },
  createErrorUniqueId = (rowData) => {
    return rowData["campaignId"]
  }
} = {}) {
  var selectMap = selectDataList.reduce((acc, row) => {
    var uniqueId = createUniqueId(row)
    acc[uniqueId] = row
    return acc
  }, {})
  var getErors = ({ results }) => {
    var errorList = results.reduce((errors, result) => {
      const apiErrors = result.APIResult.filter((r) => !r.isSuccess && !r.Success && !r.IsSuccess).map((x) => {
        var notfound = $t("amskey341")
        var errmsg = result?.ErrorMessages ? (Array.isArray(result?.ErrorMessages) ? result.ErrorMessages[0] : JSON.parse(result.ErrorMessages[0] ?? "{}")?.message || notfound) : notfound
        return {
          ...x,
          message: x.message || x.Message ? x.message || x.Message : errmsg ?? notfound
        }
      })

      return errors.concat(apiErrors)
    }, [])
    var realErrorList = errorList.map((item) => {
      var uniqueId = createErrorUniqueId(item)
      var curRowData = selectMap[uniqueId]
      return {
        ...item,
        ...curRowData,
        message: item.message,
        extraMessage: item.keywordText
      }
    })
    return realErrorList
  }
  return {
    getErors
  }
}
export function eagerComputed(fn) {
  const result = shallowRef()
  watchEffect(
    () => {
      result.value = fn()
    },
    {
      flush: "sync" // 依赖变化时立刻求值
    }
  )
  return readonly(result)
}
export function usePacvueAsyncDialog({ defaultDialogMode = "Edit" } = {}) {
  var dialogMode = ref(defaultDialogMode)
  var dialogRef = ref(null) //弹框的ref
  var watchDialogRef = ({ callback, mode } = {}) => {
    var oldDialogMode = dialogMode.value
    if (oldDialogMode == mode) {
      //业务处理逻辑
      callback && callback()
    } else {
      dialogMode.value = mode
      var unwatch = watch(dialogRef, (diaRef, oldDiaRef) => {
        if (diaRef && oldDiaRef === null) {
          //业务处理逻辑
          callback && callback()
          //取消监控
          unwatch()
          unwatch = null
        }
      })
    }
  }
  return {
    dialogMode,
    dialogRef,
    watchDialogRef
  }
}
// function PacvueGetTargetKindByCampaingType({ campaingType }) {
//   //keyword
//   if (campaingType == "SD") {
//     //PAT audience
//     // tactic: T00020:PAT;  T00030:audience
//   }
//   if (campaingType == "SP") {
//     console.log("SP")
//   }
// }
/**
 * @function {根据选中的profileId获取当前的toMarket}
 * @param {选中的ProfileId数组,Array}>} selectedProfileIds
 * @returns
 */
export function PacvueGetDefaultCurrencyToMarket({ selectedProfileIds = [] } = {}) {
  const userStore = useUserStore()
  const commonStore = useCommonStore()
  const cacheData = commonStore.adProfileList || [] //所有的profile
  let checkedPid = []
  let selectedProfileIdStrList = selectedProfileIds.map((id) => id + "")
  if (selectedProfileIds.length == 0) {
    checkedPid = cacheData
  } else {
    checkedPid = cacheData.filter((x) => {
      return selectedProfileIdStrList.indexOf(x.id) != -1
    })
  }
  var currency
  currency = checkedPid.map((r) => {
    return r.countryCode
  })
  if (Array.from(new Set(currency)).length > 1) {
    // 存在不同的市场 使用setting的currency
    return userStore.user.defaultCurrency
  } else {
    // 否则使用单个市场的currency
    return Array.from(new Set(currency))[0] || userStore?.user?.defaultCurrency
  }
}
export function useDefaultCurrencyToMarket({ searchQuery } = {}) {
  var realToMarket = computed(() => {
    var selectedProfileIds = searchQuery.value?.profileIds ?? []
    return PacvueGetDefaultCurrencyToMarket({ selectedProfileIds })
  })
  return {
    realToMarket
  }
}
/**
 * @function 将字符串进行转化
 * @param {需要格式化的字符串} val String
 * @param {转换类型,uppercase/lowercase/capitalize} transformType String
 * @param {分隔符} split String
 * @returns String
 */
export function PacvueStrFormat({ val = "", transformType = "capitalize", split = " ", ignoreList = [], ignoreTransformType = "uppercase" } = {}) {
  if (Object.prototype.toString.call(val) == "[object String]") {
    var strList = val.split(split)
    let ignoreListTemp = []
    if (Array.isArray(ignoreList)) {
      ignoreListTemp = ignoreList.map((item) => item?.toLowerCase() ?? item)
    }
    var capStrList = strList.map((item) => {
      if (Array.isArray(ignoreList)) {
        let itemLower = item?.toLowerCase()
        if (ignoreListTemp.includes(itemLower)) {
          //如果设置了忽略类型
          if (ignoreTransformType) {
            switch (ignoreTransformType) {
              case "uppercase":
                item = item.toUpperCase()
                break
              case "lowercase":
                item = item.toLowerCase()
                break
              default:
                item = strCapitalize(item)
                break
            }
          }
          return item
        }
      }
      switch (transformType) {
        case "uppercase":
          item = item.toUpperCase()
          break
        case "lowercase":
          item = item.toLowerCase()
          break
        default:
          item = strCapitalize(item)
          break
      }
      return item
    })
    val = capStrList.join(split)
  }
  return val
}
export async function PacvueSearchTermNavigator({ record, kindType = "Keyword", countryAddress = "com", menu }) {
  let queryType = ""
  let asinImgUrl = record?.asinImgUrl
  let query = record?.query || record?.Query
  let lowercaseQuery = (query || "")?.toLowerCase()
  let isAsinVal = async (asin) => {
    const commonStore = useCommonStore()
    const allProfile = commonStore?.curStore?.profileList.map((item) => item.id) || []
    let asinList = []
    try {
      let dataList = await SearchProductAdForAsinSku({
        asinOrSku: [asin],
        profileIds: allProfile
      })
      asinList = dataList.map((item) => item.asin)
    } catch (ex) {
      asinList = []
    }
    return asinList.includes(asin)
  }
  let getAsin = (query) => {
    let asin = query
    if (lowercaseQuery?.includes("asin=")) {
      let match = lowercaseQuery.match(/asin="([^"]*)"/)
      if (match) {
        asin = match[1]
      } else {
        asin = query
      }
    }
    return asin
  }

  if (kindType == "Keyword" || kindType == "keyword") {
    queryType = "Keyword"
    if (asinImgUrl) {
      queryType = "ASIN"
    } else {
      let isAsin = false
      let asinTypeCheck = lowercaseQuery?.startsWith("b0") || lowercaseQuery?.includes("asin=")
      if ((menu == "SearchTerm" || menu == "Query") && asinTypeCheck) {
        isAsin = true
      } else {
        isAsin = await isAsinVal(getAsin(query))
      }
      if (isAsin) {
        queryType = "ASIN"
      }
    }
  } else {
    queryType = "ASIN"
    if (!asinImgUrl) {
      let isAsin = false
      let asinTypeCheck = lowercaseQuery?.startsWith("b0") || lowercaseQuery?.includes("asin=")
      if (menu == "SearchTerm" && asinTypeCheck) {
        isAsin = true
      } else {
        isAsin = await isAsinVal(getAsin(query))
      }
      if (!isAsin) {
        queryType = "Keyword"
      }
    }
  }
  let href = ""
  if (queryType == "Keyword") {
    href = `https://www.amazon.${countryAddress}/s?k=${getAsin(query)}`
  } else {
    href = "https://www.amazon." + countryAddress + "/dp/" + getAsin(query).toUpperCase()
  }
  window.open(href, "_blank")
}
export function PacvueGetTypeByCamType({ campaignType } = {}) {
  let typeMap = {
    sponsoredproducts: "SP",
    headlinesearch: "SB",
    sponsoreddisplay: "SD",
    sponsoredtv: "STV"
  }
  let campaignTypeLower = campaignType
  if (typeof campaignType == "string") {
    campaignTypeLower = campaignType.toLowerCase()
  }
  return typeMap[campaignTypeLower]
}
/**
 * @function campaignType 类型转换
 * @param {*} param0
 */
export function PacvueGetCampaignTypeInfo({ type } = {}) {
  let TypeShadow = {
    SB: ["SB-Product", "SB-Multi", "SB-Video", "SB-Brand Video", "SB-Store", "SBV"],
    SD: ["SD-Product", "SDPAT", "SD-Audience"],
    SP: ["SP-Manual", "SP-Auto"],
    STV: ["STV"]
  }
  let upperType = type || "--"
  let formatType = PacvueStrFormat({ val: upperType, split: "-", ignoreList: ["SB", "SP", "SD", "STV"] })
  let keyList = Object.keys(TypeShadow)
  let result = {
    type, //原生的type
    simpleType: "SP", //简写的Type,默认为SP
    formatType //格式化的type
  }
  for (let i = 0, size = keyList.length; i < size; ++i) {
    let simpleType = keyList[i]
    let curTypeList = TypeShadow[simpleType] ?? []
    if (curTypeList.includes(formatType) || formatType?.startsWith(simpleType)) {
      result.simpleType = simpleType
      break
    }
  }
  return result
}
let fileId = 1
export const genFileId = () => Date.now() + fileId++
/**
 * 解析tagName为数组
 * @param {Object} [option={}]
 * @param {String} option.tagName 解析的tagName字符串
 * @param {String} option.tagSplit 不同tag之间的分隔符,默认为;
 * @param {String} option.innerTagSplit 父tag和子tag之间的分隔符,默认为!!
 * @returns {Array<{tagName:String,parentTagName:String}>}
 */
export function PacvueAnalysisTagName({ tagName, tagSplit = ";", innerTagSplit = "!!" } = {}) {
  let tagList = []
  if (Object.prototype.toString.call(tagName) == "[object Array]") {
    tagList = tagName
  } else if (Object.prototype.toString.call(tagName) == "[object String]") {
    tagList = tagName.split(tagSplit)
  }
  tagList = tagList || []
  let realTagList = []
  for (var i = 0, size = tagList.length; i < size; i++) {
    var tagArr = tagList[i].split(innerTagSplit)
    let tag = tagArr[0] ?? ""
    let subTag = tagArr[1]
    let tagItem = {}
    if (subTag === undefined || subTag == "") {
      tagItem.tagName = tag
    } else {
      tagItem.parentTagName = tag
      tagItem.tagName = subTag
    }
    realTagList.push(tagItem)
  }
  return realTagList
}
/**
 *
 * @param {Object} [option={}]
 * @param {String} option.tagName 解析的tagName字符串
 * @param {String} option.tagSplit 不同tag之间的分隔符,默认为;
 * @param {String} option.innerTagSplit 父tag和子tag之间的分隔符,默认为!!
 * @param {String} option.showTagSplit 显示字符串中不同tag之间的分隔符,默认为;
 * @param {String} option.showInnerTagSplit 显示字符串父tag和子tag之间的分隔符,默认为 /
 * @returns {String}
 */
export function PacvueShowCamTagWithTagName({ tagName, tagSplit = ";", innerTagSplit = "!!", showTagSplit = ",", showInnerTagSplit = " / " } = {}) {
  let tagList = PacvueAnalysisTagName({ tagName, tagSplit, innerTagSplit })
  let list = tagList.reduce((result, tagItem) => {
    let { parentTagName, tagName } = tagItem
    let innerTagList = [tagName]
    if (parentTagName) {
      innerTagList.unshift(parentTagName)
    }
    result.push(innerTagList.join(showInnerTagSplit))
    return result
  }, [])
  return list.join(showTagSplit)
}
/**
 *@function 获取日期范围的上一周期范围
 * @param {Object} [option={}]
 * @param {String} option.start  开始日期
 * @param {String} option.end 结束日期
 * @param {String} option.dateFormat 日期格式,默认为MM/DD/YYYY
 * @param {String} option.exportDateFormat 输出的日期格式,默认为MM/DD/YYYY
 * @returns {Object<{start,end}>}
 */
export function PacvueLastRangeOfDateRange({ start, end, dateFormat = "MM/DD/YYYY", exportDateFormat = "MM/DD/YYYY" } = {}) {
  //需要上一个周期的时间
  const startDate = dayjs(start, dateFormat).toDate()
  const endDate = dayjs(end, dateFormat).toDate()
  const diffDays = dayjs(endDate).diff(dayjs(startDate), "days") + 1
  const compareDate = {
    start: dayjs(startDate).subtract(diffDays, "days").format(exportDateFormat),
    end: dayjs(endDate).subtract(diffDays, "days").format(exportDateFormat)
  }
  return compareDate
}
export function PacvueLocalForageClear({ userId, platform = "amazon", clearUserIds = ["14236", "14171", "21939"], clearClientIds = ["210088", "1619"], clientId } = {}) {
  if (!userId) {
    const userStore = useUserStore()
    let userid = ""
    if (userStore?.user?.userId) {
      userid = userStore.user?.userId
    } else if (sessionStorage.getItem("useInfo")) {
      userid = JSON.parse(sessionStorage.getItem("useInfo")).userId
    }
    userId = userid + ""
  }
  userId = userId + ""
  clientId = clientId + ""
  let uniqueId = `${PERSIST_KEY_PRE}${platform}_${userId}_IndexDB_Clear`
  let state = localStorage.getItem(uniqueId)
  let isClearUser = Array.isArray(clearUserIds) && clearUserIds.includes(userId)
  let isClearClient = Array.isArray(clearClientIds) && clearClientIds.includes(clientId)
  if ((isClearUser || isClearClient) && state != "1") {
    localStorage.setItem(uniqueId, "1")
    localForageClear()
  }
}

export function PacvueDimDateFormat({ obj, dim }) {
  const { Date, week, month, quarter, year } = obj

  //范围从右上角取值
  let appStore = useAppStore()
  let startDate = computed(() => appStore.dateRange?.startDate)
  let endDate = computed(() => appStore.dateRange?.endDate)
  let betweenDate = {
    start: startDate.value,
    end: endDate.value
  }
  const { start, end } = betweenDate
  let daysTz = dayjs()
  let startTime, endTime
  switch (dim) {
    case "date":
      startTime = Date
      endTime = Date
      break
    case "week":
      startTime = daysTz.year(year).week(week).startOf("week")
      endTime = daysTz.year(year).week(week).endOf("week")
      break
    case "month":
      startTime = daysTz
        .year(year)
        .month(month - 1)
        .startOf("month")
      endTime = daysTz
        .year(year)
        .month(month - 1)
        .endOf("month")
      break
    case "quarter":
      startTime = daysTz.year(year).quarter(quarter).startOf("quarter")
      endTime = daysTz.year(year).quarter(quarter).endOf("quarter")
      break
    case "year":
      startTime = daysTz.year(year).startOf("year")
      endTime = daysTz.year(year).endOf("year")
      break
    default:
      throw new Error("Invalid dimension")
  }
  if (dim == "date") {
    // date模式返回自身日期
    return { startTime, endTime }
  }
  const betweenStartTime = dayjs(start, "MM/DD/YYYY")
  const betweenEndTime = dayjs(end, "MM/DD/YYYY")
  const adjustedStartTime = startTime.isBefore(betweenStartTime) ? betweenStartTime : startTime // 获取的日期在右上角系统日期 之前 即采用系统的日期
  const adjustedEndTime = endTime.isBefore(betweenEndTime) ? endTime : betweenEndTime
  return { startTime: adjustedStartTime.format("YYYY-MM-DD"), endTime: adjustedEndTime.format("YYYY-MM-DD") }
}
export function PacvueIsPlacementPage({ menu } = {}) {
  return ["Placement", "Placement-AmazonBusiness", "Placement-OffAmazon", "Placement-AMCAudienceBidModifier"].includes(menu)
}
