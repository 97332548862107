export default {
  retailer: true,
  budgetPlan: true,
  account: true,
  swtichOld: false,
  University: true,
  Training: true,
  Feedback: true,
  Download: true,
  Message: true,
  MyAccount: true,
  Knowledge: true,
  Synchronization: true,
  UserManage: true,
  Settings: true,
  Notification: true,
  UserCenter: true,
  analytics: true,
  AnomalyAlert: true,
  Copilot: true,
  issueClientIds: [1145],
  availablePlatforms: {
    amazon: "https://product.pacvue.com",
    walmart: "https://walmartv3.pacvue.com"
  },
  availablePlatformsNew: ["dsp"],
  Language: [
    {
      id: "EN",
      url: "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/US.png",
      name: "English"
    },
    {
      id: "JA",
      url: "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/JP.png",
      name: "日本語"
    },
    {
      id: "ZH",
      url: "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/ZH.png",
      name: "中文"
    }
  ],
  isNewLocaleAsync: true, // 是否启用新异步国际化缓存策略
  // 采集测试实例配置[applicationId, clientToken, service, blacklist<"1,2,3">]，默认空则启用生产实例
  ddConfig: [],
  coralogixConfig: [],
  pltSwitchActiveOnly: [3438], // 左上角平台切换, 仅展示开通平台的 ClientIds
  isNewRouterAsync: true
}
