import "@Message/components/notf.scss"
import { gotocheck } from "@Message/action.js"
import { download } from "@Message/api"
import { commonJS } from "@pacvue/utils"
import { PacvueMessage } from "@pacvue/element-plus"
import { useAnalyticsTrack } from "@pacvue/frame/const"
const productline = localStorage.getItem("productline") || "amazon"
const gocheck = function (item) {
  gotocheck(item, item.operationDetail)
}
let dateTime = ""
export const goDownload = (url, downloadUrl) => {
  useAnalyticsTrack("Click Download Button", { category: "Experience", "Download Source": " From Message" })
  download(downloadUrl)
    .then((res) => {
      commonJS.DownLoad(res, url)
    })
    .catch(() => {
      PacvueMessage({
        message: $t("Data Download Failed"),
        type: "error"
      })
    })
}
const goDownloadAmazon = (name, downloadUrl) => {
  useAnalyticsTrack("Click Download Button", { category: "Experience", "Download Source": " From Message" })
  let url = name.fileName

  download(downloadUrl)
    .then((res) => {
      commonJS.DownLoad(res, url)
    })
    .catch(() => {
      PacvueMessage({
        message: $t("Data Download Failed"),
        type: "error"
      })
    })
}
const slicingMessage = (val) => {
  let arr = val ? val.split("\n") : []
  return arr
}
export const renderContent = (item) => {
  if (item.type == "System" && item.messageJson) {
    let obj = JSON.parse(item.messageJson)
    let isDownUrl = obj && obj.downloadUrl && obj.downloadUrl != "" ? true : false
    if (isDownUrl) {
      return (
        <div class="flex flex-col">
          <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
          <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
          <div class="defEnBread">{String(slicingMessage(item.message)[2] || "")}</div>
          <div class="themeGoCheck cursor-pointer" onClick={() => gocheck(item)}>
            {$t("key2006")}
          </div>
        </div>
      )
    } else {
      return (
        <div class="flex flex-col">
          <div class="defEnBread">{item.message}</div>
        </div>
      )
    }
  } else {
    let hasDateRange = slicingMessage(item.message)[2] ? String(slicingMessage(item.message)[2])?.split("downloadUrl=")[0].indexOf("---") == -1 : null
    console.log("hasDateRange", hasDateRange)
    // 73是"Data Download Succeed"
    if ((item.noteId && item.noteId == 73) || item.detail === "Data Download Succeed") {
      if (dateTime != item.dateTime) {
        useAnalyticsTrack("Download Success", { category: "Browse" })
        dateTime = item.dateTime
      }
      //获取download url
      let downloadList = slicingMessage(item.message)[2] ? String(slicingMessage(item.message)[2])?.split("downloadUrl=") : ["", ""]
      // amazon国际化导致ws中message名字汉化 需特殊处理
      if (productline === "amazon") {
        return (
          <div class="flex flex-col">
            <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
            <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
            <div class="defEnBread">{hasDateRange ? downloadList[0] : ""}</div>
            <div
              class="themeGoCheck cursor-pointer"
              onClick={() => {
                return downloadList[1] ? goDownloadAmazon(JSON.parse(item.messageJson), downloadList[1]) : gocheck(item)
              }}>
              {downloadList[1] ? $t("key1408") : $t("key2006")}
            </div>
          </div>
        )
      } else {
        return (
          <div class="flex flex-col">
            <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
            <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
            <div class="defEnBread">{hasDateRange ? downloadList[0] : ""}</div>
            <div
              class="themeGoCheck cursor-pointer"
              onClick={() => {
                // return downloadList[1] ? goDownload(String(slicingMessage(item.message)[1]), String(slicingMessage(item.message)[2]), downloadList[1]) : gocheck(item)
                return downloadList[1] ? goDownload(item.fileName, downloadList[1]) : gocheck(item)
              }}>
              {downloadList[1] ? $t("key1408") : $t("key2006")}
            </div>
          </div>
        )
      }
    } else if ((item.noteId && item.noteId == 74) || item.detail === "Data Download Failed") {
      // 74是"Data Download Failed"
      if (dateTime != item.dateTime) {
        let nameObj = String(slicingMessage(item.message)[1]) ? String(slicingMessage(item.message)[1]).slice(5) : ""
        useAnalyticsTrack("Download Failure", { category: "Browse", "Download Type": nameObj })
        dateTime = item.dateTime
      }
      return (
        <div class="flex flex-col">
          <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
          <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
          <div class="defEnBread">{hasDateRange ? String(slicingMessage(item.message)[2] || "") : ""}</div>
          <div class="themeGoCheck cursor-pointer" onClick={() => gocheck(item)}>
            {$t("key2006")}
          </div>
        </div>
      )
    } else {
      if (item.operationDetail) {
        return (
          <div class="flex flex-col">
            <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
            <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
            <div class="defEnBread">{hasDateRange ? String(slicingMessage(item.message)[2] || "") : ""}</div>
            <div class="themeGoCheck cursor-pointer" onClick={() => gocheck(item)}>
              {$t("Go to Check")}
            </div>
          </div>
        )
      } else {
        return (
          <div class="flex flex-col">
            <div class="defEnBread">{String(slicingMessage(item.message)[0] || "")}</div>
            <div class="defEnBread">{String(slicingMessage(item.message)[1] || "")}</div>
            <div class="defEnBread">{hasDateRange ? String(slicingMessage(item.message)[2] || "") : ""}</div>
          </div>
        )
      }
    }
  }
}
//兼容main中的导入
export default function (item) {
  return renderContent(item)
}
