import { useAppStore, useUserStore } from "@pacvue/store"
import PDcenter from "@/views/ProductCenter/PDcenterStore"
import common from "./common/index"
import amazon from "./amazon"
import { useStoreModule } from "@pacvue/store/utils/util"

const modules = { common, amazon, PDcenter }
var { useCommonStore, useAmazonStore, usePDcenterStore } = useStoreModule(modules)

export { useAppStore, useUserStore, useCommonStore, useAmazonStore, usePDcenterStore }
