<template>
  <div class="favorites-menus bg-[var(--table-hover-color)]">
    <div v-if="favoriteMenus.length === 0" class="flex flex-col items-center justify-around py-4 w-[240px] h-[140px]">
      <i ref="favoriteStarWrapperRef" class="flex items-center justify-center w-8 h-8 rounded-[50%]">
        <el-icon :size="22" color="var(--root-menu-highlight)"><PacvueIconStarfill /></el-icon>
      </i>
      <div class="px-4 text-[14px] text-center" :class="{ 'break-keep': locale === 'EN' }">{{ $t("You can find the modules you actively collected here for easy and quick selection.") }}</div>
    </div>
    <el-scrollbar v-else wrap-class="max-h-[calc(100vh-56px)] py-2">
      <div
        v-for="menuItem in favoriteMenus"
        :key="menuItem.url"
        class="group flex items-center justify-between h-12 px-4 py-0.5 transition-all duration-300 cursor-pointer hover:bg-[var(--hover-color--)]"
        @click="handleFavorMenuItemClick(menuItem)"
      >
        <div class="menu-info">
          <div class="menu-name leading-5 text-[#66666c]">{{ menuItem.i18N }}</div>
          <div v-if="menuItem.rootMenuI18N" class="root-menu-name leading-[18px] text-[12px] font-medium text-[#b2b2b8]">{{ menuItem.rootMenuI18N }}</div>
        </div>
        <div class="favor-home flex flex-shrink-0 items-center justify-end ml-2 w-[45px]">
          <pacvue-tooltip placement="top" effect="dark" :content="homePage === menuItem.url ? $t('Remove') : $t('Homepage')" :show-after="300">
            <!-- 站外菜单不提供homapage功能 -->
            <el-icon
              :size="20"
              v-if="menuItem.url.indexOf('https') == -1"
              class="relative -top-[0.5px] group-hover:!opacity-100 cursor-pointer"
              :class="homePage === menuItem.url ? 'opacity-100' : 'opacity-0'"
              :color="homePage === menuItem.url ? 'var(--root-menu-highlight)' : '#b2b2b8'"
              @click="(event) => handleHomePageAssign(event, menuItem)"
            >
              <PacvueIconHomeSolid v-if="homePage === menuItem.url" />
              <PacvueIconHome v-else />
            </el-icon>
          </pacvue-tooltip>
          <pacvue-tooltip placement="top" effect="dark" :content="$t('Remove')" :show-after="300">
            <el-icon :size="20" class="ml-1 cursor-pointer" color="var(--root-menu-highlight)" v-collect="{ menu: menuItem, isFavorite: true }">
              <PacvueIconStarfill />
            </el-icon>
          </pacvue-tooltip>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue"
import { useRoute, useRouter } from "vue-router"
// store
import { useUserStore, useAppStore } from "@/store"
// utils
import { hexToRgba } from "../../utils/special-logic"
import { findRootMenu } from "../../utils/menu-tree"
import { jumpToRetailDashboard } from "../../utils/common.js"
// hooks
import { useSettleHomepage } from "../../hooks/menu-pref"

const emits = defineEmits(["onFavorItemClick"])

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const locale = window.locale || localStorage.getItem("locale")
const favoriteStarWrapperRef = ref(null)
const favoriteMenus = computed(() => {
  const favoritesMenuIds = userStore.userSettings?.userFavourite ?? []
  const menuItems = []
  if (favoritesMenuIds.length > 0) {
    for (const menuId of favoritesMenuIds) {
      const menuItem = window.menuIdItemMap[menuId]
      if (menuItem) {
        menuItem.rootMenuI18N = findRootMenu(menuItem)?.i18N
        menuItems.push(menuItem)
      }
    }
  }
  return menuItems
})
const { homePage, handleHomePageAssign } = useSettleHomepage()

const handleFavorMenuItemClick = (menuItem) => {
  if (menuItem.url === route.path) return
  if (menuItem.url.indexOf("https") !== -1) {
    jumpToRetailDashboard(menuItem.url, { mode: "_blank", isDomainUnified: true, isMenuRedirect: true })
    return false
  }
  emits("onFavorItemClick", menuItem)
  router.push(menuItem.url)
}

watchEffect(() => {
  if (favoriteStarWrapperRef.value) {
    const appEl = document.querySelector("#app")
    const menuHighlightColor = window.getComputedStyle(appEl).getPropertyValue("--root-menu-highlight")
    favoriteStarWrapperRef.value?.style.setProperty("background-color", hexToRgba(menuHighlightColor, 0.4))
  }
})

onMounted(() => {})
</script>

<style scoped lang="scss">
.favorites-menus {
  :deep(.el-scrollbar__bar).is-vertical {
    right: 0 !important;
  }
}
</style>
