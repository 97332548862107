import {
  PacvueIcon1PDataAnalysis,
  PacvueIcon1PDemographicAnalysis,
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddToSubtag,
  PacvueIconAddtotargeting,
  PacvueIconAdgroupReport,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdvance1,
  PacvueIconAdvance2,
  PacvueIconAdvance3,
  PacvueIconAdvertiserReport,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAiPowered,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllgood,
  PacvueIconAllRecommendations,
  PacvueIconAllStores,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonShoppingInsights,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconAnalysisMagnify,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDropDown,
  PacvueIconArrowLeft,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowRise,
  PacvueIconArrowUp,
  PacvueIconAscend,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAudienceExpansion,
  PacvueIconAudienceExpansion1,
  PacvueIconAudienceInsight,
  PacvueIconAuditAgain,
  PacvueIconAuditCenterTag,
  PacvueIconAuditHistory,
  PacvueIconAuthorize,
  PacvueIconAuto,
  PacvueIconAuto1,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBack,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrand,
  PacvueIconBrand1,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBudgetShort,
  PacvueIconBudgetShortGray,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignAi,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignHourlyReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignsToOptimize,
  PacvueIconCampaignTagReport,
  PacvueIconCampaignTypeAi,
  PacvueIconCampaignTypeSuperWizard,
  PacvueIconCancel,
  PacvueIconCategoryIntelligence,
  PacvueIconChangeLive,
  PacvueIconChangeOwner,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClockCircle,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconCltvAnalysis,
  PacvueIconCollapseDetails,
  PacvueIconColumnChart,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceAz,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommercePacvueTools,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceSales,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCommerceZa,
  PacvueIconCompetitive,
  PacvueIconCompetitorAnalysis,
  PacvueIconComplete,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeImage,
  PacvueIconCreativeReport,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCustomColumns,
  PacvueIconCustomDisplayHierarchy,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDanger,
  PacvueIconDanger2,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDayPartingPause,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDescend,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDocumentAdd,
  PacvueIconDocumentAi,
  PacvueIconDocumentGood,
  PacvueIconDocumentPause,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDowncurve,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDrag,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconElArrowRight,
  PacvueIconEmail,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconEventsAnalysis,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExplor,
  PacvueIconExplorationChart1,
  PacvueIconExplorationChart2,
  PacvueIconExplorationChart3,
  PacvueIconExplorationChart4,
  PacvueIconExplorationChart5,
  PacvueIconExplorationChart6,
  PacvueIconExplorationChart7,
  PacvueIconExplorationChart8,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFastallocation,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFromkeywordtag,
  PacvueIconFromNegativeKeyword,
  PacvueIconGainInsights,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconGroupMAmcInsights,
  PacvueIconHalfRightArrow,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHigh,
  PacvueIconHighClick,
  PacvueIconHighClickGray,
  PacvueIconHighest,
  PacvueIconHighPriorityRecommendations,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconIcAccountBalanceWallet,
  PacvueIconIconDown,
  PacvueIconIgnore,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconIncrementalRoas,
  PacvueIconIncrementalSales,
  PacvueIconIncrementalSpend,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItsumo,
  PacvueIconItsumoSmall,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordInsightTop1,
  PacvueIconKeywordInsightTop2,
  PacvueIconKeywordInsightTop3,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLandscape,
  PacvueIconLastBidChange,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLeftArrow1,
  PacvueIconLegoFunnelDashboardReport,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconLineChart1,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconLove,
  PacvueIconLow,
  PacvueIconLowClick,
  PacvueIconLowClickGray,
  PacvueIconLowComp,
  PacvueIconLowCompGray,
  PacvueIconLowest,
  PacvueIconMagnify,
  PacvueIconManage,
  PacvueIconManageTag,
  PacvueIconManualKw,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMergeToDealTerm,
  PacvueIconMessage,
  PacvueIconMiddle,
  PacvueIconMissingPicture,
  PacvueIconMobile,
  PacvueIconMonthlyRollover,
  PacvueIconMonthlyWeeklyCampaignPerformanceReport,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultilayer,
  PacvueIconNegativeKeyword,
  PacvueIconNegativeProductTargeting,
  PacvueIconNegativeTargeting,
  PacvueIconNew1PIcon,
  PacvueIconNewIcon,
  PacvueIconNewLogo,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNodata11,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverlapping,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconOverviewDashboard,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPacvueStarLine,
  PacvueIconPagefail,
  PacvueIconPagesuccess,
  PacvueIconPageWarning,
  PacvueIconPaidsearchaudit,
  PacvueIconPat,
  PacvueIconPatDetail,
  PacvueIconPc,
  PacvueIconPerfect,
  PacvueIconPerformanceChart,
  PacvueIconPeriodAnalysis,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlay,
  PacvueIconPlus,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductAi,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRank,
  PacvueIconRealTimeData,
  PacvueIconRecommdation,
  PacvueIconRecommdationAmazon,
  PacvueIconRecommendationPacvue,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRelevant,
  PacvueIconRemove,
  PacvueIconRenewal,
  PacvueIconReplace,
  PacvueIconReplay,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconRetailerDashboard,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleDetial,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSankey,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconSBicon,
  PacvueIconScheduledTask,
  PacvueIconSDicon,
  PacvueIconSearch,
  PacvueIconSearchInSearchTerm,
  PacvueIconSelectBrand,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconSetting,
  PacvueIconShareCenter,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSkip,
  PacvueIconSkip1,
  PacvueIconSolidAi,
  PacvueIconSorting,
  PacvueIconSovEditKeyword,
  PacvueIconSovMenu,
  PacvueIconSovReport,
  PacvueIconSovShare,
  PacvueIconSparkles,
  PacvueIconSpHourlyReport,
  PacvueIconSPicon,
  PacvueIconSponsoredDisplay,
  PacvueIconSpread,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconStvEffectAnalysis,
  PacvueIconSubDrag,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTag,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnauthorized,
  PacvueIconUnlike,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpcurve,
  PacvueIconUpdateTime,
  PacvueIconUpload,
  PacvueIconUpload1,
  PacvueIconUploadBox,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVector1,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconWorkplace,
  PacvueIconYearOverYearDataReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
} from "@pacvue/svg-icon"
export default {
  PacvueIcon1PDataAnalysis,
  PacvueIcon1PDemographicAnalysis,
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddToSubtag,
  PacvueIconAddtotargeting,
  PacvueIconAdgroupReport,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdvance1,
  PacvueIconAdvance2,
  PacvueIconAdvance3,
  PacvueIconAdvertiserReport,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAiPowered,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllgood,
  PacvueIconAllRecommendations,
  PacvueIconAllStores,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonShoppingInsights,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconAnalysisMagnify,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDropDown,
  PacvueIconArrowLeft,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowRise,
  PacvueIconArrowUp,
  PacvueIconAscend,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAudienceExpansion,
  PacvueIconAudienceExpansion1,
  PacvueIconAudienceInsight,
  PacvueIconAuditAgain,
  PacvueIconAuditCenterTag,
  PacvueIconAuditHistory,
  PacvueIconAuthorize,
  PacvueIconAuto,
  PacvueIconAuto1,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBack,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrand,
  PacvueIconBrand1,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBudgetShort,
  PacvueIconBudgetShortGray,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignAi,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignHourlyReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignsToOptimize,
  PacvueIconCampaignTagReport,
  PacvueIconCampaignTypeAi,
  PacvueIconCampaignTypeSuperWizard,
  PacvueIconCancel,
  PacvueIconCategoryIntelligence,
  PacvueIconChangeLive,
  PacvueIconChangeOwner,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClockCircle,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconCltvAnalysis,
  PacvueIconCollapseDetails,
  PacvueIconColumnChart,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceAz,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommercePacvueTools,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceSales,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCommerceZa,
  PacvueIconCompetitive,
  PacvueIconCompetitorAnalysis,
  PacvueIconComplete,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeImage,
  PacvueIconCreativeReport,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCustomColumns,
  PacvueIconCustomDisplayHierarchy,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDanger,
  PacvueIconDanger2,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDayPartingPause,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDescend,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDocumentAdd,
  PacvueIconDocumentAi,
  PacvueIconDocumentGood,
  PacvueIconDocumentPause,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDowncurve,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDrag,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconElArrowRight,
  PacvueIconEmail,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconEventsAnalysis,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExplor,
  PacvueIconExplorationChart1,
  PacvueIconExplorationChart2,
  PacvueIconExplorationChart3,
  PacvueIconExplorationChart4,
  PacvueIconExplorationChart5,
  PacvueIconExplorationChart6,
  PacvueIconExplorationChart7,
  PacvueIconExplorationChart8,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFastallocation,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFromkeywordtag,
  PacvueIconFromNegativeKeyword,
  PacvueIconGainInsights,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconGroupMAmcInsights,
  PacvueIconHalfRightArrow,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHigh,
  PacvueIconHighClick,
  PacvueIconHighClickGray,
  PacvueIconHighest,
  PacvueIconHighPriorityRecommendations,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconIcAccountBalanceWallet,
  PacvueIconIconDown,
  PacvueIconIgnore,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconIncrementalRoas,
  PacvueIconIncrementalSales,
  PacvueIconIncrementalSpend,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItsumo,
  PacvueIconItsumoSmall,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordInsightTop1,
  PacvueIconKeywordInsightTop2,
  PacvueIconKeywordInsightTop3,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLandscape,
  PacvueIconLastBidChange,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLeftArrow1,
  PacvueIconLegoFunnelDashboardReport,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconLineChart1,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconLove,
  PacvueIconLow,
  PacvueIconLowClick,
  PacvueIconLowClickGray,
  PacvueIconLowComp,
  PacvueIconLowCompGray,
  PacvueIconLowest,
  PacvueIconMagnify,
  PacvueIconManage,
  PacvueIconManageTag,
  PacvueIconManualKw,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMergeToDealTerm,
  PacvueIconMessage,
  PacvueIconMiddle,
  PacvueIconMissingPicture,
  PacvueIconMobile,
  PacvueIconMonthlyRollover,
  PacvueIconMonthlyWeeklyCampaignPerformanceReport,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultilayer,
  PacvueIconNegativeKeyword,
  PacvueIconNegativeProductTargeting,
  PacvueIconNegativeTargeting,
  PacvueIconNew1PIcon,
  PacvueIconNewIcon,
  PacvueIconNewLogo,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNodata11,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverlapping,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconOverviewDashboard,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPacvueStarLine,
  PacvueIconPagefail,
  PacvueIconPagesuccess,
  PacvueIconPageWarning,
  PacvueIconPaidsearchaudit,
  PacvueIconPat,
  PacvueIconPatDetail,
  PacvueIconPc,
  PacvueIconPerfect,
  PacvueIconPerformanceChart,
  PacvueIconPeriodAnalysis,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlay,
  PacvueIconPlus,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductAi,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRank,
  PacvueIconRealTimeData,
  PacvueIconRecommdation,
  PacvueIconRecommdationAmazon,
  PacvueIconRecommendationPacvue,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRelevant,
  PacvueIconRemove,
  PacvueIconRenewal,
  PacvueIconReplace,
  PacvueIconReplay,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconRetailerDashboard,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleDetial,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSankey,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconSBicon,
  PacvueIconScheduledTask,
  PacvueIconSDicon,
  PacvueIconSearch,
  PacvueIconSearchInSearchTerm,
  PacvueIconSelectBrand,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconSetting,
  PacvueIconShareCenter,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSkip,
  PacvueIconSkip1,
  PacvueIconSolidAi,
  PacvueIconSorting,
  PacvueIconSovEditKeyword,
  PacvueIconSovMenu,
  PacvueIconSovReport,
  PacvueIconSovShare,
  PacvueIconSparkles,
  PacvueIconSpHourlyReport,
  PacvueIconSPicon,
  PacvueIconSponsoredDisplay,
  PacvueIconSpread,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconStvEffectAnalysis,
  PacvueIconSubDrag,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTag,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnauthorized,
  PacvueIconUnlike,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpcurve,
  PacvueIconUpdateTime,
  PacvueIconUpload,
  PacvueIconUpload1,
  PacvueIconUploadBox,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVector1,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconWorkplace,
  PacvueIconYearOverYearDataReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
}
