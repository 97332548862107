import { app } from "./frame"
import { defineStore } from "pinia"
import { _menu2url } from "../utils/menu-tree"
import { getMenus, getAmazonList, getUserPermission } from "./api"
import { getMessages } from "@Message/api"
import { usePacvueLocalStore, useUserInfo } from "@/utils/common-util"
import { dayjs } from "@pacvue/element-plus"
import { judgeHqShow } from "../constants/env"
// import { usePDcenterStore } from "@/store"

const initSideBarExpansionState = () => {
  if (localStorage.getItem("sideBarIsExpend")) {
    return false
  } else {
    return false
  }
}

const getPermissionObj = function (menuTree) {
  let aliasPermissionMap = {}
  function recursiveFn(parentNodes) {
    parentNodes.forEach((item) => {
      aliasPermissionMap[item.alias] = item.permissionType ?? 2
      if (item.children && item.children.length > 0) {
        recursiveFn(item.children)
      }
    })
  }
  recursiveFn(menuTree)
  return aliasPermissionMap
}

const getVisibleMenus = function (menuTree, ctx) {
  window.NoPermissionPage = {}
  window.menuUrlItemMap = {}
  window.menuIdItemMap = {}
  let newMenu = []
  function recursiveFn(parentNodes) {
    parentNodes = parentNodes.filter((item) => {
      if (!item.url?.includes("https:")) {
        // 需排除绝对地址 url 对应菜单
        window.menuUrlItemMap[item.url] = item
      }
      window.menuIdItemMap[item.menuId] = item
      if (item.children && item.children.length > 0) {
        item.hasChild = true
        item.allChildInvisible = item.children.every((child) => child.invisable)
        item.children = recursiveFn(item.children)
      }
      // 特殊的无权限: 若子菜单除去 invisible 的以外权限都是 0 (原本有, 但被过滤到没有子菜单), 则无论父级菜单权限具备与否, 都是无权限
      const spNoPermission = item.permissionType > 0 && item.hasChild && item.children.length === 0 && !item.allChildInvisible
      if (item.permissionType == 0 || spNoPermission) {
        window.NoPermissionPage[item.alias] = true
      }
      if (item.permissionType != 0 && !item.invisable && !spNoPermission) {
        if (!window.firstRouter) {
          window.firstRouter = item.url
        }
        return true
      } else {
        return false
      }
    })
    return parentNodes
  }
  newMenu = recursiveFn(menuTree)
  // 全部的依赖在这个地方
  ctx.menuIdItemMap = window.menuIdItemMap
  return newMenu
}

const appendBadgesOnParent = function (menuTree) {
  function recursiveFn(parentNodes) {
    parentNodes = parentNodes.filter((item) => {
      if (item.children && item.children.length > 0) {
        item.hasNewMenu = recursiveFn(item.children).length > 0 ? true : false
      }
      if (item.betaTag || item.alphaTag || item.newTag || item.hasNewMenu) {
        return item
      }
    })
    return parentNodes
  }
  recursiveFn(menuTree)
}

const watchFields = [
  "dateRange",
  "chooseAccounts",
  "compareTime",
  "compareSummary",
  "isCompare",
  "productPermission",
  "channelType",
  "isCompareCustom",
  "isComparePop",
  "isCompareYoy",
  "latestDateMode"
]

const useFrameStore = defineStore("frameStore", {
  state: () => {
    return {
      HomePage: "",
      menuIdItemMap: {},
      sideBarIsExpend: initSideBarExpansionState(),
      menu: [],
      activeMenu: [],
      menuMap: {},
      singleTooltip: {
        visible: false,
        text: "",
        virRef: null
      },

      breadcrumb: {
        link: [],
        config: {}
      },
      dateRange: {
        startDate: dayjs().subtract(29, "days").format("MM/DD/YYYY"),
        endDate: dayjs().format("MM/DD/YYYY")
      },
      compareTime: {
        start: dayjs().subtract(59, "days").format("MM/DD/YYYY"),
        end: dayjs().subtract(30, "days").format("MM/DD/YYYY")
      },
      isCompareCustom: true,
      isComparePop: false,
      isCompareYoy: false,
      chooseAccounts: [],
      account: [],
      compareSummary: false,
      isCompare: false,
      productPermission: "",
      channelType: undefined,
      message: {
        total: 0,
        list: [],
        type: "All",
        loading: false
      },
      contentLoading: false,
      //是否显示dateCampre
      showDateCompare: false,
      customHeader: null,
      customBreadcrumbBottomComponent: null,
      activePath: "",
      realtimePage: null,
      extraOptions: null,
      isRealTimeByPST: false,
      lifeTimeMode: false,
      datepickerOptions: null,
      latestDateMode: null,
      accountAll: []
    }
  },

  getters: {},

  actions: {
    SET_HOMEURL(v) {
      this.HomePage = v
    },
    SET_LATESTDATEMODE(v) {
      this.latestDateMode = v
    },
    SET_DATEPICKEROPTIONS(v) {
      this.datepickerOptions = v
    },
    SET_ISREALTIMEBYPST(v) {
      this.isRealTimeByPST = v
    },
    SET_LIFETIMEMODE(v) {
      this.lifeTimeMode = v
    },
    SET_extraOptions(v) {
      this.extraOptions = v
    },
    SET_activePath(v) {
      this.activePath = v
    },
    async getHqMenus() {
      const preCondition = judgeHqShow()
      if (!preCondition) {
        return
      }
      let menuTree = []
      // 若 HQ 菜单存在缓存, 且调用时机不是国际化切换后, 则直接使用缓存
      if (sessionStorage.getItem("Menu-HQ") && !window.isUseHeader) {
        menuTree = JSON.parse(sessionStorage.getItem("Menu-HQ"))
      } else {
        // HQ 菜单国际化的确定，必须使用请求头中的 Language 字段
        menuTree = await getMenus({ isUseHeader: true }, "retailer")
        sessionStorage.setItem("Menu-HQ", JSON.stringify(menuTree))
      }
    },
    meueAction({ to, from } = {}) {
      return new Promise(async (resolve) => {
        // 菜单预处理, 若当前整个平台无权限 (排除 rakuten), 则所有菜单权限置 0
        const preHandleMenus = ({ productLine, menuTree = [] } = {}) => {
          const { availablePlatforms = [] } = JSON.parse(sessionStorage.getItem("useInfo") || "{}")
          const pltNoPermission = availablePlatforms.length > 0 && !availablePlatforms.includes(productLine) && productLine !== "rakuten"
          if (pltNoPermission) {
            const recursiveFn = (menuTree) => {
              menuTree.forEach((item) => {
                if (item.children?.length > 0) {
                  recursiveFn(item.children)
                }
                item.permissionType = 0
              })
            }
            recursiveFn(menuTree)
          }
          return menuTree
        }
        // 获取菜单, 优先拿缓存
        const productLine = window.productline || localStorage.getItem("productline")
        let menuTree = []
        if (sessionStorage.getItem("Menu") && !to.query.noCache) {
          menuTree = JSON.parse(sessionStorage.getItem("Menu"))
        } else {
          // isUseHeader: 当右上角切换国际化后，偏好语言的落库，与此时的请求存在异步时间差，无法保证拿到正确语言的菜单，故告知此时应取请求头中的语言字段
          const isUseHeader = productLine === "retailer" || !!window.isUseHeader
          menuTree = await getMenus({ isUseHeader })
        }
        // 菜单处理及保存
        preHandleMenus({ productLine, menuTree })
        this.SET_MENU(menuTree)
        resolve(menuTree)
      })
    },
    async SET_MENU(menuTree) {
      sessionStorage.setItem("Menu", JSON.stringify(menuTree))
      const permissionObj = getPermissionObj(menuTree)
      window.permissionObj = permissionObj
      menuTree = getVisibleMenus(menuTree, this)
      appendBadgesOnParent(menuTree)
      this.menuMap = _menu2url(menuTree)
      localStorage.setItem("Menu", JSON.stringify(menuTree))
      localStorage.setItem("MenuMap", JSON.stringify(this.menuMap))
      this.menu = menuTree
    },
    SET_ACTIVEMENU(val) {
      function enums(data, arrChild) {
        let enumCopy = []
        if (data?.children.length > 0) {
          data.children.forEach((item) => {
            enumCopy = enums(item, arrChild)
          })
        }
        if (data.menuName === val || enumCopy.length > 0) {
          arrChild.push(data.menuName)
        }
        return arrChild
      }
      let arrChild = []
      this.menu.some((item) => {
        arrChild = []
        enums(item, arrChild)
        return arrChild.length > 0
      })

      if (this.activeMenu.includes(val)) {
        this.activeMenu.splice(this.activeMenu.indexOf(val), 1)
      } else {
        this.activeMenu = arrChild
      }
    },

    setSideBarIsExpend(val) {
      this.sideBarIsExpend = val
      window.localStorage.setItem("sideBarIsExpend", val)
    },
    SET_TOOLTIPSHOW(val) {
      this.singleTooltip.visible = true
      this.singleTooltip.text = val.text
      this.singleTooltip.virRef = val.virRef
    },
    SET_TOOLTIPHIDE(val) {
      this.singleTooltip.visible = val
    },
    accountAction() {
      return new Promise((resolve, reject) => {
        if (JSON.parse(sessionStorage.getItem("account") || "[]")?.length > 0 || window.inNextGuardLoops) {
          var account = JSON.parse(sessionStorage.getItem("account"))
          this.SET_ACCOUNT(account)
          resolve(account)
        } else {
          getAmazonList({ isManage: 1 })
            .then((account) => {
              this.SET_ACCOUNT(account)
              sessionStorage.setItem("account", JSON.stringify(account))
              resolve(account)
            })
            .catch(() => {
              reject(false)
            })
        }
      })
    },
    accountActionAll() {
      return new Promise((resolve, reject) => {
        if (JSON.parse(sessionStorage.getItem("accountAll") || "[]")?.length > 0 || window.inNextGuardLoops) {
          var account = JSON.parse(sessionStorage.getItem("accountAll"))
          this.SET_ACCOUNTALL(account)
          resolve(account)
        } else {
          getAmazonList()
            .then((account) => {
              this.SET_ACCOUNTALL(account)
              sessionStorage.setItem("accountAll", JSON.stringify(account))
              resolve(account)
            })
            .catch(() => {
              reject(false)
            })
        }
      })
    },
    productPermissonAction() {
      return new Promise((resolve, reject) => {
        // resolve()
        getUserPermission({ advertiserIds: this.chooseAccounts })
          .then((account) => {
            this.SET_PRODUCTPERMISSION(account)
            resolve(account)
          })
          .catch(() => {
            reject(false)
          })
      })
    },
    SET_DATERANGE(val) {
      this.dateRange = val
    },
    SET_COMPARETIME(val) {
      this.compareTime = val
    },
    SET_ISCOMPARECUSTOM(val) {
      this.isCompareCustom = val
    },
    SET_ISCOMPAREPOP(val) {
      this.isComparePop = val
    },
    SET_ISCOMPAREYOY(val) {
      this.isCompareYoy = val
    },
    SET_BREADCRUMB(val) {
      this.breadcrumb = val
    },
    setHeaderBar(val) {
      this.customHeader = val
    },
    setCustomBreadcrumbBottomComponent(val) {
      this.customBreadcrumbBottomComponent = val
    },
    async SET_ACCOUNT(val) {
      window.localStorage.setItem("account", JSON.stringify(val))
      this.chooseAccounts = this.chooseAccounts ? (this.chooseAccounts.length == 0 ? [val[0]?.advertiserId] : this.chooseAccounts) : []
      this.account = val
    },
    async SET_ACCOUNTALL(val) {
      window.localStorage.setItem("accountAll", JSON.stringify(val))
      this.accountAll = val
    },

    async SET_PRODUCTPERMISSION(val) {
      this.productPermission = val
    },
    SET_CHOOSEACCOUNTS(val) {
      //判断旧的是否包含新的
      val = val || []
      var oldChooseAccounts = this.chooseAccounts || []
      var diffAccounts = oldChooseAccounts.filter((item) => !val.includes(item))
      if (diffAccounts && diffAccounts.length) {
        this.CLEAR_CASDADERFILTER()
      }
      this.chooseAccounts = val
    },

    SET_COMPARESUMMARY(val) {
      this.compareSummary = val
    },
    SET_ISCOMPARE(val) {
      this.isCompare = val
    },
    SET_CHANNELTYPE(val) {
      this.channelType = val
    },
    setUserPersist() {
      var userid = useUserInfo().userId
      watchFields.forEach((fieldName) => {
        var storeKey = userid + "_" + fieldName
        var storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          this[fieldName] = JSON.parse(storeStr)
        }
      })
    },
    SET_MESSAGE(isRefresh) {
      this.message.loading = true
      if (isRefresh) {
        app.config.globalProperties.$eventHub.emit("MessageRefresh")
      }
      let query = {
        caseType: [],
        isRead: 0,
        type: this.message.type,
        pageNum: 1,
        pageSize: 20
      }
      let req = Object.assign({}, query)
      if (req.type === "All") {
        req.type = null
      }
      getMessages(req)
        .then((data) => {
          // console.log(data)
          let Data = data.message
          this.message.list = Data
          this.message.total = data.allTotal
          this.message.loadingRead = {}
          data.loadingRead.forEach((e) => {
            this.message.loadingRead[e.type] = e.count
          })
        })
        .finally(() => {
          this.message.loading = false
        })
    },
    SET_MESSAGETYPE(val) {
      this.message.type = val
    },
    SET_CONTENDLOADING(val) {
      this.contentLoading = val || false
    },
    SET_SHOWDATECAMPRE(val) {
      this.showDateCompare = val || false
    },
    CLEAR_CASDADERFILTER() {
      //清空级联数据
      var pacvueLocalStore = usePacvueLocalStore()

      pacvueLocalStore.delItem("campaignIds")
      pacvueLocalStore.delItem("portfolioIds")
      var tagInfo = { ptag: [], stag: [] }
      pacvueLocalStore.sethassubTagging(0, tagInfo)
      pacvueLocalStore.sethassubTagging(1, tagInfo)
      pacvueLocalStore.sethassubTagging(2, tagInfo)
      var totalTaggingIds = []
      pacvueLocalStore.setTagging(totalTaggingIds)
    },
    SET_realtimePage(val) {
      this.realtimePage = val
    }
  },
  //开启数据缓存
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: watchFields
  }
})
export default useFrameStore
